<template>
    <div class="customer-detail">
        <customer-base-info
            ref="cusBaseInfo"
            v-loading="loading"
            :customer="customerInfo"
            :customerId="customerId"
            @refresh="handleRefresh"
        ></customer-base-info>
        <router-view
            :customerId="customerId"
            :customerName="customerInfo.name"
        ></router-view>

        <!-- <div class="lbd-sign"></div> -->
    </div>
</template>

<script>
import CustomerBaseInfo from './layout/customer-base-info.vue';
import CustomerService from '#/js/service/customerService.js';
export default {
    name: "customerDetail",
    components: {
        CustomerBaseInfo
    },
    data() {
        return {
            loading: false,
            customerId: "",
            customerInfo: {
                name: "",
                fullName: "",
                city: 0,
                locations: [],
                industryLevel1Id: 0,
                industryId: 0,
                companyScale: 0,
                webSite: "",
                departments: [],
                products: [],
                description: ""
            }
        }
    },
    created() {
        this.customerId = this.$route.params.customerId;
    },
    mounted() {
        this.getCustomerInfo();
    },
    methods: {
        handleRefresh() {
            this.getCustomerInfo();
        },
        getCustomerInfo() {
            this.loading = true;
            CustomerService.getCustomerDetail({
                customerId: this.customerId
            }).then(res => {
                this.loading = false;
                res.industryLevel1Id = res.industryLevel1Id ? res.industryLevel1Id + '' : "";
                res.industryId = res.industryId ? res.industryId + '' : "";
                res.webSite = res.webSite ? res.webSite : "";
                res.departments = res.departments ? res.departments : [];
                res.locations = res.locations ? res.locations : [];
                res.products = res.products ? res.products : [];
                Object.assign(this.customerInfo, res);
                this.$nextTick(() => {
                    this.$refs.cusBaseInfo.checkFoldBtn();
                    this.$nextTick(() => {
                        if(location.href.indexOf('department') > -1) {
                            this.$el.querySelector(".department-list-wrapper").scrollIntoView();
                        }
                    })
                })
                let firmName = this.$store.state.user.userInfo.firmShortName;
                let title = "";
                if(location.href.indexOf('department') > -1) {
                    title = '组织架构_' + this.customerInfo.name + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
                } else {
                    title = '客户_' + this.customerInfo.name + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
                }
                document.title = title;
            }).catch(err => {
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="scss" scope>
    .customer-detail {
        // width: 1200px;
        // margin: 0 auto;
        // margin-bottom: 56px;
        padding: 20px;
        margin-bottom: 20px;
        // padding-top: 30px;
        height: 100%;
        overflow-y: auto;

        .lbd-sign {
            padding-top: 20px;
            &:after {
                content: '© 2024 liebide.com';
                margin-left: 50%;
                text-align: center;
                font-size: 14px;
                color: #999;
            }
        }
    }
</style>