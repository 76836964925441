<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogFormVisible"
        custom-class="contract-info-dialog"
        @before-close="handleBeforeClose"
        width="700px"
    >
        <div
            class="conract-header"
            slot="title"
        >
            <div class="step active">合同信息</div>
            <div class="line"></div>
            <div class="step">付款方式</div>
        </div>
        <!-- size="small" -->
        <el-form
            :model="form"
            :rules="rules"
            ref="contractForm"
            size="small"
        >
            <el-form-item
                :label-width="formLabelWidth"
                label="客户"
            >
                <el-input
                    autocomplete="off"
                    class="limit-width"
                    disabled
                    v-model="name"
                ></el-input>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="合同编号"
                prop="contractNumber"
            >
                <el-input
                    autocomplete="off"
                    class="limit-width"
                    maxlength="20"
                    v-focus
                    v-model="form.contractNumber"
                ></el-input>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="客户主体"
                prop="customerSubject"
            >
                <el-input
                    autocomplete="off"
                    class="limit-width"
                    maxlength="20"
                    v-model="form.customerSubject"
                ></el-input>
                <el-button
                    @click="addSubject"
                    icon="el-icon-plus"
                    type="text"
                >添加</el-button>
            </el-form-item>
            <el-form-item
                :key="domain.key"
                :label="'客户主体' + (index+2)"
                :label-width="formLabelWidth"
                :prop="'subjects.' + index + '.value'"
                :rules="{
                    required: true, message: '客户主体不能为空', trigger: 'blur'
                }"
                v-for="(domain, index) in form.subjects"
            >
                <el-input
                    class="limit-width"
                    v-model="domain.value"
                    maxlength="20"
                ></el-input>
                <el-button
                    @click.prevent="removeSubject(domain)"
                    class="del"
                    icon="el-icon-remove-outline"
                    type="text"
                >删除</el-button>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="合同类别"
                prop="contractType"
            >
                <el-select
                    class="limit-width"
                    multiple
                    placeholder="请选择合同类别"
                    v-model="form.contractType"
                >
                    <el-option
                        :key="'opt_' + index"
                        :label="it.label"
                        :value="it.value"
                        v-for="(it, index) in contractTypeMap"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="签订人"
                prop="signedUserId"
            >
                <!-- <el-select
                    class="limit-width"
                    placeholder="请选择活动区域"
                    v-model="form.signedUserId"
                >
                    <el-option
                        label="区域一"
                        value="shanghai"
                    ></el-option>
                    <el-option
                        label="区域二"
                        value="beijing"
                    ></el-option>
                </el-select>-->
                <el-popover
                    placement="bottom-start"
                    popper-class="team-member-popover customer-team-member-popover"
                    trigger="click"
                    v-model="teamMemberPopoverVisible"
                    width="700"
                >
                    <team-member-select
                        :isAllMembers="true"
                        :isShowDimission="false"
                        :isSingleSelect="true"
                        :selected="form.signedUserId"
                        :visible="teamMemberPopoverVisible"
                        @item-select-cb="membersSelect"
                        ref="teamSelect"
                    ></team-member-select>
                    <el-input
                        :readonly="true"
                        class="limit-width"
                        placeholder="选择签定人"
                        slot="reference"
                        suffix-icon="el-icon-arrow-down"
                        v-model="creators"
                    ></el-input>
                </el-popover>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="签订日期"
                prop="signedDate"
            >
                <!-- @change="handleDateChange" -->
                <el-date-picker
                    class="limit-width"
                    placeholder="请选择合同签订日期"
                    type="date"
                    v-model="form.signedDate"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item
                :label-width="formLabelWidth"
                label="合同有效时间"
                prop="effectiveStartTime"
            >
                <el-date-picker
                    class="limit-width"
                    end-placeholder="结束日期"
                    range-separator="-"
                    start-placeholder="开始日期"
                    type="daterange"
                    v-model="form.effectiveStartTime"
                ></el-date-picker>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="签约主体"
                prop="signedEntity"
            >
                <el-select
                    class="limit-width"
                    placeholder="请选择签约主体"
                    v-model="form.signedEntity"
                >
                    <el-option
                        :key="'opt_' + index"
                        :label="it.label"
                        :value="it.value"
                        v-for="(it, index) in entityMap"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="合同附件"
                prop="attachments"
            >
                <!-- <el-upload
                    :file-list="fileList"
                    :http-request="handleUpload"
                    :limit="5"
                    :multiple="false"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    accept=".bmp, .jpg, .png"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    class="apply-upload"
                    ref="attachmentUpload"
                >
                    <el-button
                        size="small"
                        type="primary"
                    >点击上传</el-button>
                    <div
                        class="el-upload__tip"
                        slot="tip"
                    >只能上传bmp, jpg, png文件，且不超过2.0M</div>
                </el-upload>-->
                <input
                    @change="changeFile"
                    accept="image/jpeg, image/png, image/gif, application/pdf"
                    class="input"
                    id="attachment"
                    style="position:absolute; clip:rect(0 0 0 0);"
                    type="file"
                />
                <el-button
                    :loading="uploadAttachmentLoading"
                    @click="uploadAttachment"
                    size="small"
                    type="primary"
                    v-if="form.attachments.length == 0"
                >
                    <i class="el-icon-upload"></i> 上传
                </el-button>
                <div
                    class="file-cell"
                    v-else
                >
                    <div class="icon">
                        <i class="el-icon-document-copy"></i>
                    </div>
                    <div class="file-name">{{form.fileName}}</div>
                    <div class="opt">
                        <el-button
                            :loading="uploadAttachmentLoading"
                            @click="uploadAttachment"
                            size="small"
                            type="text"
                        >替换</el-button>
                    </div>
                </div>

                <!-- onclick="document.formInput.fileDemo.click()"> -->
            </el-form-item>

            <el-form-item
                :label-width="formLabelWidth"
                label="合同费率说明"
            >
                <el-input
                    :autosize="{ minRows: 4, maxRows: 6}"
                    maxlength="200"
                    placeholder="请输入合同费率说明"
                    show-word-limit
                    type="textarea"
                    v-model="form.description"
                ></el-input>
            </el-form-item>
        </el-form>

        <div
            class="dialog-footer"
            slot="footer"
        >
            <el-button @click="handleCancel">取 消</el-button>

            <el-button
                @click="handleTap('edit')"
                type="primary"
                v-if="isEdit"
            >保 存</el-button>
            <el-button
                @click="handleTap('next')"
                type="primary"
                v-else
            >下一步</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {
    entityMap,
    contractTypeMap,
} from '../config/config-map';


import TeamMemberSelect from '#/component/common/team-member-select.vue';
import CustomerService from '#/js/service/customerService.js';
// addContractAttachment

export default {
    name: 'contract-info-dialog',
    components: {
        TeamMemberSelect,
    },
    data () {
        return {
            dialogFormVisible: false,

            teamMemberPopoverVisible: false,
            selectedUserIds: [],
            creators: '',

            fileList: [],


            form: {
                contractNumber: '',
                customerSubject: '',
                contractType: '',
                signedUserId: [],
                signedEntity: '',
                signedDate: '',
                effectiveStartTime: [],
                effectiveEndTime: '',
                attachments: [],
                fileName: '',
                description: '',

                subjects: [],
            },
            rules: {
                contractNumber: [
                    { required: true, message: '请填写合同编号', trigger: ['blur', 'change'] },
                ],
                customerSubject: [
                    { required: true, message: '请填写客户主体', trigger: 'blur' },
                    // { validator: this.validateTaxpayerNo, trigger: ['blur', 'change'] },
                ],
                contractType: [
                    { required: true, message: '请选择合同类别', trigger: ['blur', 'change'] },
                ],
                signedUserId: [
                    { required: true, message: '请选择签订人', trigger: ['blur', 'change'] },
                ],
                signedEntity: [
                    { required: true, message: '请选择签约主体', trigger: ['blur', 'change'] },
                ],
                signedDate: [
                    { required: true, message: '请选择签订日期', trigger: ['blur', 'change'] },
                ],
                effectiveStartTime: [
                    { required: true, message: '请选择合同有效时间', trigger: ['blur', 'change'] },
                ],
                attachments: [
                    { required: true, message: '请上传合同附件', trigger: ['blur', 'change'] },
                ],
            },
            formLabelWidth: '120px',

            entityMap: entityMap,
            contractTypeMap: contractTypeMap,


            isEdit: false,
            uploadAttachmentLoading: false,
        };
    },
    computed: {
        name () {
            return this.$parent.customer.name;
        },
    },
    created () { },
    mounted () { },
    methods: {
        show (isEdit = false, contractInfo = {}, operationType) {
            this.isEdit = isEdit;
            this.dialogFormVisible = true;

            if (isEdit) {
                this.form.contractNumber = contractInfo.contractNumber;

                if(contractInfo.customerSubject.includes(',')) {
                    let arr = contractInfo.customerSubject.split(",");
                    this.form.customerSubject = arr[0];
                    this.form.subjects = arr.slice(1).map(it => ({
                        key: Date.now(),
                        value: it,
                    }));
                } else {
                    this.form.customerSubject = contractInfo.customerSubject;
                }
                
                this.form.contractType = contractInfo.contractType.split(",").map(it => +it);
                this.form.signedUserId = [contractInfo.signedUserId];
                this.form.signedEntity = +contractInfo.signedEntity;
                this.form.signedDate = contractInfo.signedDate;

                this.form.effectiveStartTime = [contractInfo.effectiveStartTime, contractInfo.effectiveEndTime];
                this.form.attachments = contractInfo.attachments[0]?.fileId;
                this.form.fileName = contractInfo.attachments[0]?.fileName;
                this.form.description = contractInfo.description;
                this.creators  = contractInfo.signedUser?.realName + '@' + contractInfo.signedUser?.nickname;
            } else {
                if(operationType == 'pre') {
                    Object.assign(this.form, contractInfo);
                } else{
                    // Object.assign(this.$data.form, this.$options.data().form);
                    this.form = this.$options.data().form;
                    this.creators = '';
                }
            }
        },

        // 上传入职证明
        handleUpload ({ file, onSuccess }) {
            // console.log(file.type)
            let isImage = false;
            if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/bmp') {
                isImage = true;
            } else {
                isImage = false;
            }
            // console.log(isImage)
            if (!isImage) {
                shortTips("只能上传bmp, jpg, png文件");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if (fileName.length > 100) {
                shortTips("文件名长度不能超过100！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            if (file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            if (this.fileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.attachmentUpload.uploadFiles.pop();
                return;
            }

            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.fileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                });
            }).catch(err => {
                this.$refs.attachmentUpload.uploadFiles.pop();
                if (err.error) shortTips(err.error.description);
            });
        },
        // 上传离职证明
        handleUploadLeavingFileList ({ file, onSuccess }) {
            // console.log(file.type)
            let fileType = [".txt", ".gif", ".jpg", ".png", ".doc", ".docx", ".pdf", ".zip", ".rar"];
            var isType = false;
            var fileEnd = file.name.substring(file.name.lastIndexOf("."));
            // console.log(fileEnd)
            if (fileType && fileType.length > 0) {
                for (var i = 0; i < fileType.length; i++) {
                    if (fileType[i] == fileEnd) {
                        isType = true;
                        break;
                    }
                }
            }
            if (!isType) {
                shortTips("只能上传txt、gif、jpg、png、doc、docx、pdf、zip、rar文件");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            const fileName = file.name.slice(0, file.name.lastIndexOf("."));
            if (fileName.length > 100) {
                shortTips("文件名长度不能超过100！");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            if (file.size / 1024 / 1024 > 2) {
                shortTips("文件大小不得超过2M");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            if (this.leavingFileList.findIndex(item => item.name == file.name) >= 0) {
                shortTips("不要重复上传同一文件！");
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                return;
            }

            let formData = new FormData();
            formData.append(`file`, file);

            floatingListService.invoicingAttachmentsAdd(formData).then(res => {
                this.leavingFileList.push({
                    name: file.name,
                    size: file.size,
                    FileId: res,
                });
            }).catch(err => {
                this.$refs.leavingCertificateAttachmentsUpload.uploadFiles.pop();
                if (err.error) shortTips(err.error.description);
            });
        },
        handleRemove () {

        },

        addSubject () {
            this.form.subjects.push({
                value: '',
                key: Date.now()
            });
        },

        removeSubject (item) {
            var index = this.form.subjects.indexOf(item);
            if (index !== -1) {
                this.form.subjects.splice(index, 1);
            }
        },

        handleTap (type) {
            // 编辑修改客户信息
            this.$refs['contractForm'].validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    this.$emit('hold-cb', type, this.form);
                    this.dialogFormVisible = false;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        resetForm () {
            this.$refs['contractForm'].resetFields();
        },

        handleCancel () {
            this.resetForm();
            this.dialogFormVisible = false;
        },
        handleBeforeClose() {
            this.creators = '';
            this.resetForm();
        },

        membersSelect (userIds, text) {
            console.log(`membersSelect:`, userIds, text);
            this.creators = text || '全部成员';
            if (userIds && userIds.length > 0) {
                this.form.signedUserId = userIds;
            }
            this.teamMemberPopoverVisible = false;
        },

        changeFile (e) {
            console.log(`-------changeFile-----`);
            console.log(e);
            // // 第一种方法，通过document.getElementById获取
            // var uploadElement = document.getElementById('upload1');
            // var file1 = uploadElement.files[0];

            // // 第二种方法，通过onchane函数获取
            // function handleFileSelect(e){
            //     var file2 = e.target.files[0];
            // }
            const file = e.target.files[0];
            const farr = file.name.split('.');
            const filenameExt = farr[farr.length -1];
            const whiteListExt = ['pdf', 'png', 'jpg', 'gif', 'doc', 'jpeg'];
            if(!whiteListExt.includes(filenameExt)) {
                return shortTips(`上传文件格式必须是pdf,png,jpg,gif,doc的`);
            }

            if (file.size / 1024 / 1024 > 4) { 
                return shortTips(`上传文件不能超过4M`);
            }

            let formData = new FormData();
            formData.append(`File`, file);

            this.uploadAttachmentLoading = true;
            CustomerService
                .addContractAttachment(formData)
                .then(res => {
                    console.log(`res:`, res);
                    this.form.fileName = file.name;
                    this.form.attachments = [res];
                    this.$refs.contractForm.validateField('attachments', (valid) => { });
                })
                .finally(() => {
                    this.uploadAttachmentLoading = false;
                });
        },

        uploadAttachment () {
            document.querySelector('#attachment').click();
        },
    },
}
</script>

<style lang="scss" scope>
.contract-info-dialog {
    .conract-header {
        width: 60%;
        display: inline-flex;
        align-items: center;
        .step {
            color: #666;
            font-size: 16px;
        }
        .line {
            height: 1px;
            width: 180px;
            background: #ccc;
            margin-left: 16px;
            margin-right: 16px;
        }
        .active {
            color: $primary;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .el-dialog__body {
        max-height: 680px;
        // max-height: 660px;
        overflow-y: auto;

        .limit-width {
            width: 350px;
        }

        .del {
            color: $red;
        }

        .el-range-input {
            background: white;
        }

        .file-cell {
            width: 350px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon {
                width: 20px;
                > i {
                    vertical-align: top;
                }
            }
            .file-name {
                flex: 1;
                max-width: 250px;
            }
            .opt {
                width: 60px;
            }
        }
    }
    .dialog-footer{
        padding-top: 6px;
    }
}

.team-member-popover.customer-team-member-popover {
    transform: translateX(0);
}
</style>