<template>
    <div class="empty-data" >
        <span class="empty-img"></span>
        <p class="empty-text">暂无数据</p>
    </div>
</template>

<script>
export default {
    name: 'lbd-empty',
	components: {
	},
	data() {
		return {
        };
    },
    created() {},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scope>
.empty-data {
    margin: 10px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-img {
        display: inline-block;
        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
        background-size: contain;
        width: 150px;
        height: 150px;
    }
    .empty-text {
        line-height: 20px;
        margin-bottom: 0px;
    }
}
</style>