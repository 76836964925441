var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.dialogFormVisible,
        "custom-class": "payment-dialog",
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        "before-close": _vm.handleBeforeClose,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "conract-header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", { staticClass: "step" }, [_vm._v("合同信息")]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "step active" }, [_vm._v("付款方式")]),
        ]
      ),
      _c(
        "el-card",
        { staticClass: "box-card", attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "card-top",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "ht-cell" },
                [
                  _vm.payType == "other"
                    ? [
                        _c("span", [_vm._v("其它付款方式:")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: {
                              placeholder: "请选择",
                              size: "small",
                              disabled: _vm.operationType == "edit",
                            },
                            model: {
                              value: _vm.defaultPayment.paymentCategory,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "paymentCategory",
                                  $$v
                                )
                              },
                              expression: "defaultPayment.paymentCategory",
                            },
                          },
                          _vm._l(_vm.otherPaymentType, function (it, index) {
                            return _c("el-option", {
                              key: "payment_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ]
                    : _c("span", [_vm._v("默认付款方式:")]),
                ],
                2
              ),
              _c("div", { staticClass: "ht-cell" }),
            ]
          ),
          _c(
            "div",
            { staticClass: "card-head" },
            [
              _vm.payType == "other"
                ? _c(
                    "el-row",
                    [
                      _vm.defaultPayment.paymentCategory == 1
                        ? [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("职级范围:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c("el-autocomplete", {
                                  staticClass: "inline-input",
                                  attrs: {
                                    "fetch-suggestions": _vm.querySearch,
                                    placeholder: "请输入职级",
                                    size: "small",
                                  },
                                  on: { select: _vm.handleSelect },
                                  model: {
                                    value: _vm.defaultPayment.jobGrade,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.defaultPayment,
                                        "jobGrade",
                                        $$v
                                      )
                                    },
                                    expression: "defaultPayment.jobGrade",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.defaultPayment.paymentCategory == 2
                        ? [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("薪资范围:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "salary-ipt",
                                    attrs: {
                                      size: "small",
                                      placeholder: "年薪最小值",
                                    },
                                    model: {
                                      value: _vm.defaultPayment.minAnnualSalary,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.defaultPayment,
                                          "minAnnualSalary",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "defaultPayment.minAnnualSalary",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "append-btn",
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [_vm._v("K")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "salary-space",
                                attrs: { span: 1 },
                              },
                              [_vm._v("-")]
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "salary-ipt",
                                    attrs: {
                                      size: "small",
                                      placeholder: "年薪最大值",
                                    },
                                    model: {
                                      value: _vm.defaultPayment.maxAnnualSalary,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.defaultPayment,
                                          "maxAnnualSalary",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "defaultPayment.maxAnnualSalary",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "append-btn",
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [_vm._v("K")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 5 } }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("保证期:")]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-input-number", {
                        staticClass: "width30",
                        attrs: {
                          max: 90000,
                          min: 1,
                          label: "描述文字",
                          size: "small",
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.defaultPayment.overWarrantyPeriod,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.defaultPayment,
                              "overWarrantyPeriod",
                              $$v
                            )
                          },
                          expression: "defaultPayment.overWarrantyPeriod",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticClass: "width30",
                          attrs: { placeholder: "请选择", size: "small" },
                          model: {
                            value:
                              _vm.defaultPayment.overWarrantyPeriodDateType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "overWarrantyPeriodDateType",
                                $$v
                              )
                            },
                            expression:
                              "defaultPayment.overWarrantyPeriodDateType",
                          },
                        },
                        _vm._l(_vm.PeriodDateType, function (it, index) {
                          return _c("el-option", {
                            key: "payment_" + index,
                            attrs: { label: it.label, value: it.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("付款方式:")]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width30",
                          attrs: { placeholder: "请选择", size: "small" },
                          model: {
                            value: _vm.defaultPayment.paymentMethodType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "paymentMethodType",
                                $$v
                              )
                            },
                            expression: "defaultPayment.paymentMethodType",
                          },
                        },
                        _vm._l(_vm.paymentMap, function (it, index) {
                          return _c("el-option", {
                            key: "payment_" + index,
                            attrs: { label: it.label, value: it.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.defaultPayment.paymentMethodType == 1
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v("首尾款比例:"),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "width30",
                              attrs: { size: "small", placeholder: "请选择" },
                              on: { change: _vm.handleFirstPaymentChange },
                              model: {
                                value:
                                  _vm.defaultPayment.firstPaymentProportion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultPayment,
                                    "firstPaymentProportion",
                                    $$v
                                  )
                                },
                                expression:
                                  "defaultPayment.firstPaymentProportion",
                              },
                            },
                            _vm._l(_vm.percentOptions, function (item) {
                              return _c("el-option", {
                                key: "opt_" + item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-input",
                            {
                              staticClass: "width30",
                              attrs: {
                                placeholder: "尾款",
                                size: "small",
                                readonly: "",
                              },
                              model: {
                                value:
                                  _vm.defaultPayment.balancePaymentProportion,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.defaultPayment,
                                    "balancePaymentProportion",
                                    $$v
                                  )
                                },
                                expression:
                                  "defaultPayment.balancePaymentProportion",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.defaultPayment.paymentMethodType == 0
            ? [
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _c("i", { staticClass: "el-icon-document-copy" }),
                  _vm._v(" 全款\n                "),
                ]),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("开票时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstInvoicePeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstInvoicePeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstInvoicePeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.firstInvoicePeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "firstInvoicePeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.firstInvoicePeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstInvoicePeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstInvoicePeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstInvoicePeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("回款时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstRefundPeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstRefundPeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstRefundPeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.firstRefundPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "firstRefundPeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.firstRefundPeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstRefundPeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstRefundPeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstRefundPeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _c("i", { staticClass: "el-icon-document-checked" }),
                  _vm._v(" 首款\n                "),
                ]),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("开票时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstInvoicePeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstInvoicePeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstInvoicePeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.firstInvoicePeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "firstInvoicePeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.firstInvoicePeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstInvoicePeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstInvoicePeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstInvoicePeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("回款时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstRefundPeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstRefundPeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstRefundPeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.firstRefundPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "firstRefundPeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.firstRefundPeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.firstRefundPeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "firstRefundPeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.firstRefundPeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _c("i", { staticClass: "el-icon-document-add" }),
                  _vm._v(" 尾款\n                "),
                ]),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("开票时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment
                                  .balanceInvoicePeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "balanceInvoicePeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.balanceInvoicePeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.balanceInvoicePeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "balanceInvoicePeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.balanceInvoicePeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.balanceInvoicePeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "balanceInvoicePeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.balanceInvoicePeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [_vm._v("回款时间:")]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.balanceRefundPeriodStartType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "balanceRefundPeriodStartType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.balanceRefundPeriodStartType",
                            },
                          },
                          _vm._l(_vm.PeriodStartType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodStartType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                        _c("el-input-number", {
                          staticClass: "width30",
                          attrs: {
                            max: 90000,
                            min: 1,
                            label: "描述文字",
                            size: "small",
                          },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.defaultPayment.balanceRefundPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.defaultPayment,
                                "balanceRefundPeriod",
                                $$v
                              )
                            },
                            expression: "defaultPayment.balanceRefundPeriod",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "width30",
                            attrs: { placeholder: "请选择", size: "small" },
                            model: {
                              value:
                                _vm.defaultPayment.balanceRefundPeriodDateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.defaultPayment,
                                  "balanceRefundPeriodDateType",
                                  $$v
                                )
                              },
                              expression:
                                "defaultPayment.balanceRefundPeriodDateType",
                            },
                          },
                          _vm._l(_vm.PeriodDateType, function (it, index) {
                            return _c("el-option", {
                              key: "PeriodDateType_" + index,
                              attrs: { label: it.label, value: it.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.operationType == "create"
        ? [
            _c(
              "div",
              { staticClass: "mid-cell" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-plus",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.addPayment },
                  },
                  [_vm._v("添加其它付款方式")]
                ),
              ],
              1
            ),
            _vm._l(_vm.otherPayments, function (payItem, index) {
              return _c(
                "el-card",
                {
                  key: "otherPayments_" + index,
                  staticClass: "box-card",
                  staticStyle: { "margin-top": "30px" },
                  attrs: { shadow: "never" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card-top",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ht-cell" },
                        [
                          _c("span", [_vm._v("其它付款方式:")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "width30",
                              attrs: { placeholder: "请选择", size: "small" },
                              model: {
                                value: payItem.paymentCategory,
                                callback: function ($$v) {
                                  _vm.$set(payItem, "paymentCategory", $$v)
                                },
                                expression: "payItem.paymentCategory",
                              },
                            },
                            _vm._l(_vm.otherPaymentType, function (it, index) {
                              return _c("el-option", {
                                key: "otherPaymentType_" + index,
                                attrs: { label: it.label, value: it.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ht-cell opt" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "del",
                              attrs: {
                                icon: "el-icon-remove-outline",
                                size: "small",
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOtherItemDelete(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "card-head" },
                    [
                      _c(
                        "el-row",
                        [
                          payItem.paymentCategory == 1
                            ? [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v("职级范围:"),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c("el-autocomplete", {
                                      staticClass: "inline-input",
                                      attrs: {
                                        "fetch-suggestions": _vm.querySearch,
                                        placeholder: "请输入职级",
                                        size: "small",
                                      },
                                      on: { select: _vm.handleSelect },
                                      model: {
                                        value: payItem.jobGrade,
                                        callback: function ($$v) {
                                          _vm.$set(payItem, "jobGrade", $$v)
                                        },
                                        expression: "payItem.jobGrade",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          payItem.paymentCategory == 2
                            ? [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _vm._v("薪资范围:"),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "salary-ipt",
                                        attrs: {
                                          size: "small",
                                          placeholder: "年薪最小值",
                                        },
                                        model: {
                                          value: payItem.minAnnualSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              payItem,
                                              "minAnnualSalary",
                                              $$v
                                            )
                                          },
                                          expression: "payItem.minAnnualSalary",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "append-btn",
                                            attrs: { slot: "append" },
                                            slot: "append",
                                          },
                                          [_vm._v("K")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "salary-space",
                                    attrs: { span: 1 },
                                  },
                                  [_vm._v("-")]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "salary-ipt",
                                        attrs: {
                                          size: "small",
                                          placeholder: "年薪最大值",
                                        },
                                        model: {
                                          value: payItem.maxAnnualSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              payItem,
                                              "maxAnnualSalary",
                                              $$v
                                            )
                                          },
                                          expression: "payItem.maxAnnualSalary",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "append-btn",
                                            attrs: { slot: "append" },
                                            slot: "append",
                                          },
                                          [_vm._v("K")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v("保证期:"),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c("el-input-number", {
                                staticClass: "width30",
                                attrs: {
                                  max: 90000,
                                  min: 1,
                                  label: "描述文字",
                                  size: "small",
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: payItem.overWarrantyPeriod,
                                  callback: function ($$v) {
                                    _vm.$set(payItem, "overWarrantyPeriod", $$v)
                                  },
                                  expression: "payItem.overWarrantyPeriod",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "width30",
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small",
                                  },
                                  model: {
                                    value: payItem.overWarrantyPeriodDateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "overWarrantyPeriodDateType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "payItem.overWarrantyPeriodDateType",
                                  },
                                },
                                _vm._l(
                                  _vm.PeriodDateType,
                                  function (it, index) {
                                    return _c("el-option", {
                                      key: "payment_" + index,
                                      attrs: {
                                        label: it.label,
                                        value: it.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v("付款方式:"),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "width30",
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small",
                                  },
                                  model: {
                                    value: payItem.paymentMethodType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "paymentMethodType",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.paymentMethodType",
                                  },
                                },
                                _vm._l(_vm.paymentMap, function (it, index) {
                                  return _c("el-option", {
                                    key: "payment_" + index,
                                    attrs: { label: it.label, value: it.value },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      payItem.paymentMethodType == 1
                        ? _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _vm._v("首尾款比例:"),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "width30",
                                      attrs: {
                                        size: "small",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change:
                                          _vm.handleItemFirstPaymentChange,
                                      },
                                      model: {
                                        value: payItem.firstPaymentProportion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            payItem,
                                            "firstPaymentProportion",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payItem.firstPaymentProportion",
                                      },
                                    },
                                    _vm._l(_vm.percentOptions, function (item) {
                                      return _c("el-option", {
                                        key: "opt_" + item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "width30",
                                      attrs: {
                                        placeholder: "尾款",
                                        size: "small",
                                        readonly: "",
                                      },
                                      model: {
                                        value: payItem.balancePaymentProportion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            payItem,
                                            "balancePaymentProportion",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payItem.balancePaymentProportion",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("%"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  payItem.paymentMethodType == 0
                    ? [
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "left" } },
                          [
                            _c("i", { staticClass: "el-icon-document-copy" }),
                            _vm._v(" 全款\n                    "),
                          ]
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("开票时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.firstInvoicePeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstInvoicePeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstInvoicePeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.firstInvoicePeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "firstInvoicePeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.firstInvoicePeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstInvoicePeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstInvoicePeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstInvoicePeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("回款时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstRefundPeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstRefundPeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstRefundPeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.firstRefundPeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "firstRefundPeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.firstRefundPeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstRefundPeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstRefundPeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstRefundPeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "left" } },
                          [
                            _c("i", {
                              staticClass: "el-icon-document-checked",
                            }),
                            _vm._v(" 首款\n                    "),
                          ]
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("开票时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.firstInvoicePeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstInvoicePeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstInvoicePeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.firstInvoicePeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "firstInvoicePeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.firstInvoicePeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstInvoicePeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstInvoicePeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstInvoicePeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("回款时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstRefundPeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstRefundPeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstRefundPeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.firstRefundPeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "firstRefundPeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.firstRefundPeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value: payItem.firstRefundPeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "firstRefundPeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.firstRefundPeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "left" } },
                          [
                            _c("i", { staticClass: "el-icon-document-add" }),
                            _vm._v(" 尾款\n                    "),
                          ]
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("开票时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.balanceInvoicePeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "balanceInvoicePeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.balanceInvoicePeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.balanceInvoicePeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "balanceInvoicePeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.balanceInvoicePeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.balanceInvoicePeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "balanceInvoicePeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.balanceInvoicePeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _vm._v("回款时间:"),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.balanceRefundPeriodStartType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "balanceRefundPeriodStartType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.balanceRefundPeriodStartType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodStartType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodStartType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  staticClass: "width30",
                                  attrs: {
                                    max: 90000,
                                    min: 1,
                                    label: "描述文字",
                                    size: "small",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: payItem.balanceRefundPeriod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        payItem,
                                        "balanceRefundPeriod",
                                        $$v
                                      )
                                    },
                                    expression: "payItem.balanceRefundPeriod",
                                  },
                                }),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width30",
                                    attrs: {
                                      placeholder: "请选择",
                                      size: "small",
                                    },
                                    model: {
                                      value:
                                        payItem.balanceRefundPeriodDateType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          payItem,
                                          "balanceRefundPeriodDateType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payItem.balanceRefundPeriodDateType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.PeriodDateType,
                                    function (it, index) {
                                      return _c("el-option", {
                                        key: "PeriodDateType_" + index,
                                        attrs: {
                                          label: it.label,
                                          value: it.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            }),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            { attrs: { justify: "space-between", type: "flex" } },
            [
              _c(
                "el-col",
                { staticClass: "pre-wrap", attrs: { span: 10 } },
                [
                  _vm.operationType == "create"
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleTap("pre")
                            },
                          },
                        },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _vm.operationType == "create"
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "付款方式可不填写先完成合同创建,之后在合同详情中再补充",
                            effect: "light",
                            placement: "left",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTap("notNow")
                                },
                              },
                            },
                            [_vm._v("暂不填写")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.operationType == "edit"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTap("edit")
                            },
                          },
                        },
                        [_vm._v("保 存")]
                      )
                    : _vm._e(),
                  _vm.operationType == "add"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTap("add")
                            },
                          },
                        },
                        [_vm._v("补充付款方式")]
                      )
                    : _vm._e(),
                  _vm.operationType == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTap("create")
                            },
                          },
                        },
                        [_vm._v("创 建")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }