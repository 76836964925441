var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "customer-edit-dialog",
      attrs: {
        title: "编辑客户",
        visible: _vm.dialogVisible,
        width: "660px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "customer-edit-form",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司简称",
                        required: true,
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "常用简称/知名度较高的称呼" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司全称", prop: "fullName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "营业执照上的公司全称" },
                        model: {
                          value: _vm.form.fullName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fullName", $$v)
                          },
                          expression: "form.fullName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址", prop: "address" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户详细地址" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属行业", prop: "industryId" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "small-select-row",
                          attrs: { gutter: 12 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.industryLevel1Id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "industryLevel1Id",
                                        $$v
                                      )
                                    },
                                    expression: "form.industryLevel1Id",
                                  },
                                },
                                _vm._l(_vm.industryMap, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.industryId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "industryId", $$v)
                                    },
                                    expression: "form.industryId",
                                  },
                                },
                                _vm._l(_vm.industrySubTreeMap, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.isCFUser
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属领域", prop: "domainId" } },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "small-select-row",
                              attrs: { gutter: 12 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.form.domainLevel1Id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "domainLevel1Id",
                                            $$v
                                          )
                                        },
                                        expression: "form.domainLevel1Id",
                                      },
                                    },
                                    _vm._l(_vm.domainMap, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.form.domainId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "domainId", $$v)
                                        },
                                        expression: "form.domainId",
                                      },
                                    },
                                    _vm._l(_vm.domainSubMap, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司性质", prop: "businessNature" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.form.businessNature,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "businessNature", $$v)
                            },
                            expression: "form.businessNature",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _vm._l(_vm.natureList, function (nature, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: nature, value: nature },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分层重要性", prop: "importantLevel" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.form.importantLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "importantLevel", $$v)
                            },
                            expression: "form.importantLevel",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "A", value: "A" },
                          }),
                          _c("el-option", {
                            attrs: { label: "B", value: "B" },
                          }),
                          _c("el-option", {
                            attrs: { label: "C", value: "C" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司网站", prop: "webSite" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.webSite,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "webSite", $$v)
                          },
                          expression: "form.webSite",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司人数",
                                prop: "companyScale",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  model: {
                                    value: _vm.form.companyScale,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyScale", $$v)
                                    },
                                    expression: "form.companyScale",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "请选择", value: "" },
                                  }),
                                  _vm._l(
                                    _vm.companyScaleMap,
                                    function (value, key) {
                                      return _c("el-option", {
                                        key: "companyScaleMap_" + key,
                                        attrs: { label: value, value: key },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "研发人员数量",
                                prop: "developerCount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.form.developerCount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "developerCount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "form.developerCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司描述" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: "5",
                          maxlength: "2000",
                          placeholder:
                            "介绍下公司主要的产品，背景，以及主要创始人的资历",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }