<template>
    <div class="customer-base-info" v-loading="customerInfoLoading">
        <div class="customer-operate" v-if="!isEditBaseInfo">
            <!-- <span
                class="text-operate-btn"
                v-if="isCustomerManage"
                @click="handleCustomerCreate">
                <em>合并到另一客户</em>
            </span> -->
            <el-button 
                v-if="isCustomerManage" 
                type="primary" 
                class="customer-top-btn plain"
                @click="handleCustomerCreate"
            >合并到另一客户</el-button>
            <el-button 
                v-if="isCustomerManage" 
                type="primary" 
                class="customer-top-btn plain"
                @click="editBaseInfo"
            >编辑</el-button>
            <el-button 
                v-if="hasBatchEditPermission" 
                type="primary" 
                class="customer-top-btn"
                @click="jumpBatchCreateDepartment"
            >批量创建组织架构</el-button>
            <!-- <span class="text-operate-btn" v-if="isCustomerManage" @click="editBaseInfo">
                编辑
            </span> -->
            <!-- <span class="text-operate-btn" v-if="hasBatchEditPermission" @click="jumpBatchCreateDepartment">
                批量创建组织架构
            </span> -->
            <!-- <span class="text-operate-btn framework-icon-btn" @click="jumpBatchCreateDepartment">
                <font-icon class="framework-icon" href="#icon-framework"></font-icon>
                批量创建组织架构
            </span> -->
        </div>
        <div class="customer-name">
            <span
                class="customer-short-name"
                :title="customer.name">
                {{customer.name}}
            </span>
            <span class="customer-full-name" :title="customer.fullName">{{customer.fullName}}</span>
        </div>
        <div class="customer-tags">
            <span v-if="customer.importantLevel">{{customer.importantLevel}}</span>
            <span v-if="customer.businessNature">{{customer.businessNature}}</span>
            <span v-if="customer.developerCount">研发{{customer.developerCount}}人</span>
        </div>
        <template v-if="!isEditBaseInfo">
            <div class="customer-location" v-if="customer.locations && customer.locations.length > 0 && customer.locations[0] !== null">
                <i class="el-icon-location-outline customer-info-icon"></i>
                {{customer.locations && customer.locations.length ? customer.locations.join(' | ') : ""}}
            </div>
            <div class="customer-more-detail">
                <span class="customer-industry">
                    <i class="el-icon-price-tag customer-info-icon"></i>
                    {{customer.industryId | industryFilter(customer.industryLevel1Id)}}
                </span>
                <span class="customer-company-scale">
                    <i class="el-icon-user customer-info-icon"></i>
                    {{customer.companyScale | companyScaleFilter}}
                </span>
                <span class="customer-website">
                    <i class="el-icon-office-building customer-info-icon"></i>
                    {{customer.webSite || "暂无公司网址"}}
                </span>
                <span class="customer-website" v-if="isCFUser">
                    <i class="el-icon-guide customer-info-icon"></i>
                    {{customer.domainName || "暂无公司领域"}}
                </span>
            </div>

            <!-- tab -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <!-- :label="tab.label+`(${contactList.length})`" -->
                <el-tab-pane
                    v-for="(tab, index) in customerBaseInfoTabs"
                    :key="'customerBaseInfoTab_' + index"
                    :name="tab.name"
                >
                    <template slot="label">
                        {{ tab.label }}
                        <template v-if="tab.name == 'customerDepartment'">({{customer.departments.length}})</template>
                        <template v-if="tab.name == 'customerProduction'">({{customer.products.length}})</template>
                        <template v-if="tab.name == 'customerContact'">({{contactListTotal}})</template>
                        <template v-if="tab.name == 'customerBusinessDevelopment'">({{BDList.length}})</template>
                    </template>
                </el-tab-pane>
            </el-tabs>
            <!-- 相关部门 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerDepartment'">
                <div class="customer-base-info-content customer-department-content" :style="isShowDepFoldBtn && isFoldDepartmentText ? 'height: 40px;' : ''">
                    <span class="customer-department-item" v-for="(department, index) in customer.departments" :key="index">{{department}}</span>
                    <span v-if="!customer.departments || customer.departments.length === 0">暂无相关部门信息</span>
                    <span
                        class="show-more-or-hide"
                        v-if="isShowDepFoldBtn"
                        @click="isFoldDepartmentText = !isFoldDepartmentText">
                        {{isFoldDepartmentText ? '(更多)': '(收起)'}}
                    </span>
                </div>
            </div>
            <!-- 相关产品 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerProduction'">
                <div class="customer-base-info-content customer-production-content" :style="isShowProFoldBtn && isFoldProductionText ? 'height: 40px;' : ''">
                    <span class="customer-production-item" v-for="(production, index) in customer.products" :key="index">{{production}}</span>
                    <span v-if="!customer.products || customer.products.length === 0">暂无相关产品信息</span>
                    <span
                        class="show-more-or-hide"
                        v-if="isShowProFoldBtn"
                        @click="isFoldProductionText = !isFoldProductionText">
                        {{isFoldProductionText ? '(更多)': '(收起)'}}
                    </span>
                </div>
            </div>
            <!-- 公司介绍 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerContent'">
                <div class="customer-base-info-content customer-content-content">{{customer.description || "暂无公司介绍"}}</div>
            </div>
            <!-- 客户联系人 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerContact'">
                <div class="customer-base-info-btn">
                    <p class="customer-contact-tip"><i class="el-icon-warning-outline"></i>客户联系人仅创建者和内控可见</p>
                    <!-- <span v-if="isCustomerManage" @click="createCusContact"> -->
                    <span @click="createCusContact">
                        <i class="el-icon-plus"></i>
                        添加联系人
                    </span>
                </div>
                <div class="customer-base-info-content customer-contact-list">
                    <template v-if="!isCreateContect">
                        <template v-for="(contact, index) in contactList">
                            <div
                                class="customer-contact-item"
                                :key="index"
                                v-if="!contact.isEdit">
                                <span class="contact-item-item contact-item-name">
                                    {{contact.name}}
                                </span>
                                <span class="contact-item-item">
                                    岗位：
                                    <span class="contact-item-item-detail">{{contact.title}}</span>
                                </span>
                                <span class="contact-item-item">
                                    电话：
                                    <span class="contact-item-item-detail">{{contact.phone}}</span>
                                </span>
                                <span class="contact-item-item">
                                    邮箱：
                                    <span class="contact-item-item-detail">{{contact.email}}</span>
                                </span>
                                <span class="contact-item-item contact-item-description">
                                    描述：
                                    <span class="contact-item-item-detail">{{contact.description}}</span>
                                </span>
                                <!-- <span class="contact-item-edit-btn text-operate-btn" v-if="isCustomerManage" @click="editContactItem(contact)" style="width: auto;"> -->
                                <span class="contact-item-edit-btn text-operate-btn" @click="editContactItem(contact)" style="width: auto;">
                                    <i class="el-icon-edit-outline"></i>
                                    编辑
                                </span>
                            </div>
                            <el-form
                                :ref="`contactForm-${index}`"
                                :key="index"
                                :model="contactForm"
                                label-width="96px"
                                :rules="contactFormRules"
                                v-if="contact.isEdit"
                                class="customer-info-form">
                                <el-form-item
                                    label="姓名"
                                    prop="name"
                                    class="inline-form-item">
                                    <el-input v-model="contactForm.name" :maxlength="128" placeholder="姓名"></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="职位"
                                    prop="title"
                                    class="inline-form-item">
                                    <el-input v-model="contactForm.title" :maxlength="128" placeholder="职位"></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="电话"
                                    prop="phone"
                                    class="inline-form-item">
                                    <el-input v-model="contactForm.phone" placeholder="电话"></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="邮箱"
                                    prop="email"
                                    class="inline-form-item">
                                    <el-input v-model="contactForm.email" placeholder="邮箱"></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="描述"
                                    prop="description"
                                    class="block-form-item">
                                    <el-input
                                        v-model="contactForm.description"
                                        :maxlength="200"
                                        type="textarea"
                                        show-word-limit
                                        :rows="4"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item class="btn-form-item">
                                    <el-button type="primary" @click="editContactItemConfirm(contact, index)">保存</el-button>
                                    <el-button @click="contact.isEdit = false;">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </template>
                        <span class="show-more-item-btn" v-if="contactListTotal > contactList.length" @click="getContactList">
                            加载更多
                            <i class="el-icon-arrow-down"></i>
                        </span>
                    </template>
                    <el-form
                        ref="contactForm"
                        :model="contactForm"
                        label-width="96px"
                        :rules="contactFormRules"
                        v-if="isCreateContect"
                        class="customer-info-form">
                        <el-form-item
                            label="姓名"
                            prop="name"
                            class="inline-form-item">
                            <el-input v-model="contactForm.name" :maxlength="128" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item
                            label="职位"
                            prop="title"
                            class="inline-form-item">
                            <el-input v-model="contactForm.title" :maxlength="128" placeholder="职位"></el-input>
                        </el-form-item>
                        <el-form-item
                            label="电话"
                            prop="phone"
                            class="inline-form-item">
                            <el-input v-model="contactForm.phone" placeholder="电话"></el-input>
                        </el-form-item>
                        <el-form-item
                            label="邮箱"
                            prop="email"
                            class="inline-form-item">
                            <el-input v-model="contactForm.email" placeholder="邮箱"></el-input>
                        </el-form-item>
                        <el-form-item
                            label="描述"
                            prop="description"
                            class="block-form-item">
                            <el-input
                                v-model="contactForm.description"
                                :maxlength="200"
                                type="textarea"
                                show-word-limit
                                :rows="4"
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="btn-form-item">
                            <el-button type="primary" @click="createContactItemConfirm">保存</el-button>
                            <el-button @click="isCreateContect = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!-- 客户开发与业务发展 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerBusinessDevelopment'">
                <!-- <div class="customer-base-info-title">
                    客户开发与业务发展
                    <span class="base-info-title-detail">
                        ({{BDList.length}})
                    </span>
                    <span class="base-info-operate text-operate-btn" v-if="isCustomerManage" @click="createCusBD">
                        <i class="el-icon-plus"></i>
                        添加BD
                    </span>
                </div> -->
                <div class="customer-base-info-btn">
                    <span v-if="isCustomerManage" @click="createCusBD">
                        <i class="el-icon-plus"></i>
                        添加BD
                    </span>
                </div>
                <div class="customer-base-info-content customer-BD-list">
                    <template v-if="!isCreateBD">
                    <!-- <template> -->
                        <template v-for="(item, index) in BDList">
                            <div
                                class="customer-BD-item"
                                :key="index"
                                v-if="!item.isEdit">
                            <!-- <div
                                class="customer-BD-item"
                                :key="index"> -->
                                <span class="BD-item-item">
                                    用户：{{item.realName}}
                                </span>
                                <span class="BD-item-item">
                                    分成比例：{{item.propertion}}%
                                </span>
                                <div class="BD-item-operate-btn-list" v-if="isCustomerManage">
                                    <span class="BD-item-edit-btn text-operate-btn" @click="editBDItem(item)">
                                        <i class="el-icon-edit-outline"></i>
                                        编辑
                                    </span>
                                    <span class="BD-item-edit-btn text-operate-btn" @click="deleteBDtItem(item)">
                                        <i class="el-icon-close"></i>
                                        删除
                                    </span>
                                </div>
                            </div>
                            <el-form
                                :ref="`BDForm-${index}`"
                                :key="index"
                                :model="BDForm"
                                label-width="96px"
                                :rules="BDFormRules"
                                v-if="item.isEdit"
                                class="customer-info-form">
                                <el-form-item
                                    label="用户"
                                    prop="userId"
                                    class="inline-form-item">
                                    <el-select v-model="BDForm.userId" placeholder="请选择用户">
                                        <el-option
                                            v-for="(member, index) in memberList"
                                            :key="index"
                                            :label="member.realName"
                                            :value="member.userId">
                                            {{member.realName}}
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item
                                    label="分成比例"
                                    prop="propertion"
                                    :rules="BDFormRules.propertion"
                                    class="inline-form-item">
                                    <el-input v-model="BDForm.propertion">
                                        <span class="customer-propertion-suffix" slot="suffix">%</span>
                                    </el-input>
                                </el-form-item>
                                <el-form-item class="btn-form-item">
                                    <el-button type="primary" @click="editBDItemConfirm(item, index)">保存</el-button>
                                    <el-button @click="item.isEdit = false">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </template>
                    </template>
                    <template>
                        <div v-if="coordinators.length>0" :key="index">
                            综合协调人：
                            <!-- <span 
                                class="general-coordinator-item"
                                v-for="(item,index) in coordinators"
                                :key="index">
                                {{item.realName}}@{{item.nickname}}
                            </span> -->
                            <avatar
                                class="accepted-headhunters-avatar"
                                size="md"
                                v-for="item in coordinators"
                                :src="item.avatarId"
                                :userId="item.id"
                                :realName="item.realName"
                                :nickName="item.nickname"
                                :enableCard="true"
                                :enableName="true"
                                :enableLink="true"
                                :showGenCoordinator="true"
                                :key="item.id"
                            ></avatar>
                        </div>
                    </template>
                    <el-form
                        ref="BDForm"
                        :model="BDForm"
                        label-width="96px"
                        :rules="BDFormRules"
                        v-if="isCreateBD"
                        class="customer-info-form">
                        <el-form-item
                            label="用户"
                            prop="userId"
                            class="inline-form-item">
                            <el-select v-model="BDForm.userId" placeholder="请选择用户">
                                <el-option
                                    v-for="(member, index) in memberList"
                                    :key="index"
                                    :label="member.realName"
                                    :value="member.userId">
                                    {{member.realName}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="分成比例"
                            prop="propertion"
                            :rules="BDFormRules.propertion"
                            class="inline-form-item">
                            <el-input v-model="BDForm.propertion">
                                <span class="customer-propertion-suffix" slot="suffix">%</span>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="btn-form-item">
                            <el-button type="primary" @click="createBDItemConfirm">保存</el-button>
                            <el-button @click="isCreateBD = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

             <!-- 客户合同 -->
            <div class="customer-base-info-item" v-show="activeName == 'customerContract'">
                <!-- v-if="contractStep === 'contractList'" -->
                <div class="conrtract-wrap" v-if="contractStep === 'contractList'">
                    <el-button 
                        type="primary" 
                        icon="el-icon-plus" 
                        v-if="isCustomerManage"
                        @click="onAddContract">添加合同
                    </el-button>

                    <el-card style="margin-top: 8px;" v-for="(it, index) in contractList" :key="'contract_'+index">
                        <el-descriptions :title="`合同编号:${it.contractNumber}`" :column="2" >
                            <div slot="extra">
                                <el-radio 
                                    v-model="setAsDefaultPayment" 
                                    :label="it.id" 
                                    border
                                    disabled
                                    v-if="isFinancial && (it.id ==setAsDefaultPayment && setAsDefaultPayment!='')"
                                >默认付款方式</el-radio>
                                <el-radio 
                                    v-model="setAsDefaultPayment" 
                                    :label="it.id" 
                                    border
                                    v-if="isCustomerManage"
                                    @change="setDefaultChange"
                                >设为默认付款方式</el-radio>
                                <el-button
                                    size="small"
                                    type="text"
                                    @click="handleItemDetail(it)"
                                >查看详情</el-button>
                                <el-button
                                    v-if="!it.hasPaymentSettings && !isHaveExpried(it) && isCustomerManage"
                                    size="small"
                                    type="text"
                                    @click="addPayment(it)"
                                >补充付款方式</el-button>
                            </div>
                            <el-descriptions-item label="客户主体">{{it.customerSubject}}</el-descriptions-item>
                            <el-descriptions-item label="签订人">{{it.signedUser?.realName + '@' + it.signedUser?.nickname}}</el-descriptions-item>
                        
                            <el-descriptions-item label="有效期">{{it.effectiveStartTime | formatDate }} 至 {{it.effectiveEndTime | formatDate }}
                                <el-tag size="small" type="warning" v-if="isUnEffect(it)">待生效</el-tag>
                                <el-tag size="small" v-if="isInValid(it)">生效中</el-tag>
                                <el-tag size="small" type="info" v-if="isHaveExpried(it)">已过期</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="合同附件">
                                <!-- TODO: 这里期望能在线预览 -->
                                <el-tag size="small" 
                                    v-for="(sit,idx) in it.attachments"
                                    :key="'attachment_' + idx">
                                    {{ sit.fileName }}
                                </el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                        
                    </el-card>

                    <lbd-empty
                        v-if="contractList.length == 0">
                    </lbd-empty>
                </div>

                <div class="conrtract-wrap" 
                    v-if="contractStep == 'contractDetail'" 
                    v-loading="contractDetailLoading"
                    >
                    <el-button type="primary" 
                        @click="backContractList"><i class="el-icon-d-arrow-left"></i> 返回合同列表</el-button>
                    <el-descriptions title="合同信息" :column="3" border style="margin-top:12px;"
                        label-class-name="my-label" 
                        content-class-name="contract-content-cell"
                        >
                        <div slot="extra">
                            <el-button
                                type="text"
                                @click="onContractInfoEdit"
                                v-if="isCustomerContractManage || isAdministrator"
                            >编辑</el-button>
                        </div>
                        <el-descriptions-item label="合同编号">{{ contractDetail.contractNumber }}</el-descriptions-item>
                        <el-descriptions-item label="客户主体">{{ contractDetail.customerSubject }}</el-descriptions-item>
                        <el-descriptions-item label="合同类别">猎头</el-descriptions-item>

                        <el-descriptions-item label="签订日期">{{ contractDetail.signedDate | formatDate }}</el-descriptions-item>
                        <el-descriptions-item label="合同有效期">{{ contractDetail.effectiveStartTime | formatDate }} ~ {{ contractDetail.effectiveEndTime | formatDate }}</el-descriptions-item>
                        <el-descriptions-item label="签约主体">{{ contractDetail.signedEntity | filterSignedEntity }}</el-descriptions-item>

                        <el-descriptions-item label="签约人">{{contractDetail.signedUser?.realName + '@' + contractDetail.signedUser?.nickname}}</el-descriptions-item>
                        <!-- <el-descriptions-item label="客户合同联系人">某某人</el-descriptions-item>
                        <el-descriptions-item label="客户合同联系人电话">猎头</el-descriptions-item>

                        <el-descriptions-item label="客户合同联系人邮箱">77777777@gmail.co</el-descriptions-item> -->
                        <el-descriptions-item label="合同附件" :span="2">
                            <span
                                class="att-file"
                                v-for="(it,idx) in contractDetail.attachments"
                                :key="'attachment_' + idx"
                            >{{ it.fileName }} <i style="color: #999; margin-left: 10px;">上传时间：{{ it.created }}</i></span>
                        </el-descriptions-item>
                        <el-descriptions-item label="合同费率说明" :span="3">
                            <pre class="contract-des" v-html="contractDetail.description"></pre>
                        </el-descriptions-item>

                    </el-descriptions>

                    <!-- 默认付款方式 -->
                    <!-- label-class-name="my-label" content-class-name="my-content" -->
                    <el-descriptions 
                        title="付款信息" 
                        label-class-name="my-label" 
                        :column="3" 
                        border 
                        style="margin-top:20px;">
                        <div slot="extra">
                            <el-button
                                type="text"
                                @click="onPaymentAdd"
                                v-if="isCustomerContractManage || isAdministrator"
                            >添加</el-button>
                        </div>

                        <template v-if="defaultIsEmpty">
                            <!-- <el-empty :image-size="200"></el-empty> -->
                            <el-descriptions-item :span="3">
                                <div class="no-data">
                                    <span class="empty-img"></span>
                                    <p class="empty-data">暂无数据</p>
                                </div>
                            </el-descriptions-item>
                        </template>
                        <template v-else>
                            <el-descriptions-item label="付款方式" :span="3">
                                <i class="el-icon-bank-card"></i>
                                默认付款方式 
                                <el-button
                                    type="text"
                                    @click="onPaymentEdit(defaultPayment)"
                                    title="编辑默认付款方式"
                                    v-if="isCustomerContractManage || isAdministrator"
                                >编辑付款方式 </el-button>
                            </el-descriptions-item>

                            <el-descriptions-item label="保证期">
                                {{ defaultPayment.overWarrantyPeriod }} 个 
                                {{ getTpyeText('overWarrantyPeriodDateType', defaultPayment.overWarrantyPeriodDateType)}}
                            </el-descriptions-item>
                            <el-descriptions-item label="付款方式" content-class-name="my-content">
                                {{ getTpyeText('paymentMethodType', defaultPayment.paymentMethodType)}}
                            </el-descriptions-item>

                            <!-- 全款 -->
                            <template v-if="defaultPayment.paymentMethodType == 0">
                                <el-descriptions-item label="首尾款比" content-class-name="my-content">无</el-descriptions-item>
                                <el-descriptions-item label="全款开票时间">
                                    <!-- 入职后 10 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.firstInvoicePeriodStartType) }}
                                    {{ defaultPayment.firstInvoicePeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.firstInvoicePeriodDateType) }}
                                </el-descriptions-item>
                                <el-descriptions-item label="全款回款时间" :span="2">
                                    <!-- 开票后 20 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.firstRefundPeriodStartType) }}
                                    {{ defaultPayment.firstRefundPeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.firstRefundPeriodDateType) }}
                                </el-descriptions-item>
                            </template>
                            <!-- 首尾款 -->
                            <template v-else>
                                <el-descriptions-item label="首尾款比" content-class-name="my-content">{{defaultPayment.firstPaymentProportion}}% ：{{defaultPayment.balancePaymentProportion}}%</el-descriptions-item>
                                <el-descriptions-item label="首款开票时间">
                                    <!-- 入职后 10 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.firstInvoicePeriodStartType) }}
                                    {{ defaultPayment.firstInvoicePeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.firstInvoicePeriodDateType) }}
                                </el-descriptions-item>
                                <el-descriptions-item label="首款回款时间" :span="2">
                                    <!-- 开票后 20 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.firstRefundPeriodStartType) }}
                                    {{ defaultPayment.firstRefundPeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.firstRefundPeriodDateType) }}
                                </el-descriptions-item>
                                <el-descriptions-item label="尾款开票时间">
                                    <!-- 入职后 10 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.balanceInvoicePeriodStartType) }}
                                    {{ defaultPayment.balanceInvoicePeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.balanceInvoicePeriodDateType) }}
                                </el-descriptions-item>
                                <el-descriptions-item label="尾款回款时间" :span="2">
                                    <!-- 开票后 20 工作日 -->
                                    {{ getTpyeText('PeriodStartType', defaultPayment.balanceRefundPeriodStartType) }}
                                    {{ defaultPayment.balanceRefundPeriod }} 
                                    {{ getTpyeText('PeriodDateType', defaultPayment.balanceRefundPeriodDateType) }}
                                </el-descriptions-item>
                            </template>
                        </template>
                        
                    </el-descriptions>

                    <!-- 其他付款方式 -->
                    <el-descriptions 
                        label-class-name="my-label" 
                        :column="3" border style="margin-top:4px;"
                        v-for="(payIt, index) in otherPayment"
                        :key="'other_'+ index"
                        >
                        <el-descriptions-item label="付款方式" :span="3">
                            <i class="el-icon-postcard"></i>
                            其他付款方式( {{ getTpyeText('otherPaymentType', payIt.paymentCategory) }} 
                                <template v-if="payIt.paymentCategory == 2">
                                    【{{payIt.minAnnualSalary}}K-{{payIt.maxAnnualSalary}}K】
                                </template>
                                <template v-else>
                                    职级【{{payIt.jobGrade}}】
                                </template>
                            ) 
                            <el-button
                                type="text"
                                @click="onPaymentEdit(payIt)"
                                title="编辑其他付款方式"
                                v-if="isCustomerManage"
                            >编辑付款方式 </el-button>
                        </el-descriptions-item>

                        <el-descriptions-item label="保证期">
                            {{ payIt.overWarrantyPeriod }} 个 
                            {{ getTpyeText('overWarrantyPeriodDateType', payIt.overWarrantyPeriodDateType)}}
                        </el-descriptions-item>
                        <el-descriptions-item label="付款方式" content-class-name="my-content">
                            {{ getTpyeText('paymentMethodType', payIt.paymentMethodType)}}
                        </el-descriptions-item>

                        <template v-if="payIt.paymentMethodType == 0">
                            <el-descriptions-item label="首尾款比" content-class-name="my-content">无</el-descriptions-item>
                            <el-descriptions-item label="全款开票时间">
                                <!-- 入职后 10 工作日 -->
                                {{ getTpyeText('PeriodStartType', payIt.firstInvoicePeriodStartType) }}
                                {{ payIt.firstInvoicePeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.firstInvoicePeriodDateType) }}
                            </el-descriptions-item>
                            <el-descriptions-item label="全款回款时间" :span="2">
                                <!-- 开票后 20 工作日 -->
                                {{ getTpyeText('PeriodStartType', payIt.firstRefundPeriodStartType) }}
                                {{ payIt.firstRefundPeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.firstRefundPeriodDateType) }}
                            </el-descriptions-item>
                        </template>

                        <template v-else>
                            <el-descriptions-item label="首尾款比" content-class-name="my-content">{{payIt.firstPaymentProportion}}% ：{{payIt.balancePaymentProportion}}%</el-descriptions-item>
                            <el-descriptions-item label="首款开票时间">
                                {{ getTpyeText('PeriodStartType', payIt.firstInvoicePeriodStartType) }}
                                {{ payIt.firstInvoicePeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.firstInvoicePeriodDateType) }}
                            </el-descriptions-item>
                            <el-descriptions-item label="首款回款时间" :span="2">
                                {{ getTpyeText('PeriodStartType', payIt.firstRefundPeriodStartType) }}
                                {{ payIt.firstRefundPeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.firstRefundPeriodDateType) }}
                            </el-descriptions-item>
                            
                            <el-descriptions-item label="尾款开票时间">
                                {{ getTpyeText('PeriodStartType', payIt.balanceInvoicePeriodStartType) }}
                                {{ payIt.balanceInvoicePeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.balanceInvoicePeriodDateType) }}
                            </el-descriptions-item>
                            <el-descriptions-item label="尾款回款时间" :span="2">
                                {{ getTpyeText('PeriodStartType', payIt.balanceRefundPeriodStartType) }}
                                {{ payIt.balanceRefundPeriod }} 
                                {{ getTpyeText('PeriodDateType', payIt.balanceRefundPeriodDateType) }}
                            </el-descriptions-item>
                        </template>

                    </el-descriptions>

                    <el-popover
                        placement="right"
                        title="操作日志"
                        width="900"
                        trigger="hover"
                        :open-delay="600"
                        ref="dcPopover"
                        @show="handlePopoverShow"
                        >
                        <el-button type="primary" slot="reference" style="margin-top: 10px;"
                            v-if="isCustomerManage"
                            >
                            <i class="el-icon-notebook-2"></i> 查看操作日志
                        </el-button>
                        <el-table
                            max-height="350"
                            :data="operationLogData"
                            v-loading="operationLogLoading"
                            style="width: 100%"
                        >
                            <el-table-column
                                label="日期"
                                prop="created"
                                width="180"
                            ></el-table-column>
                            <el-table-column
                                label="用户名"
                                width="220"
                            >
                                <template slot-scope="{row}">
                                    {{ row.creator?.nickname }} @ {{ row.creator?.realName }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作类型"
                                width="180"
                            >
                                <template slot-scope="{row}">
                                    {{ row.operationType | filterOperationType }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="日志"
                            >
                                <template slot-scope="{row}">
                                    {{ generateLogs(row) }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-popover>
                    
                </div>

            </div>
            

            <!-- <div class="customer-base-info-item customer-department">
                <div class="customer-base-info-title">
                    相关部门
                    <span class="base-info-title-detail">
                        ({{customer.departments.length}})
                    </span>
                </div>
                <div class="customer-base-info-content customer-department-content" :style="isShowDepFoldBtn && isFoldDepartmentText ? 'height: 40px;' : ''">
                    <span class="customer-department-item" v-for="(department, index) in customer.departments" :key="index">{{department}}</span>
                    <span v-if="!customer.departments || customer.departments.length === 0">暂无相关部门信息</span>
                    <span
                        class="show-more-or-hide"
                        v-if="isShowDepFoldBtn"
                        @click="isFoldDepartmentText = !isFoldDepartmentText">
                        {{isFoldDepartmentText ? '(更多)': '(收起)'}}
                    </span>
                </div>
            </div>
            <div class="customer-base-info-item customer-production">
                <div class="customer-base-info-title">
                    相关产品
                    <span class="base-info-title-detail">
                        ({{customer.products.length}})
                    </span>
                </div>
                <div class="customer-base-info-content customer-production-content" :style="isShowProFoldBtn && isFoldProductionText ? 'height: 40px;' : ''">
                    <span class="customer-production-item" v-for="(production, index) in customer.products" :key="index">{{production}}</span>
                    <span v-if="!customer.products || customer.products.length === 0">暂无相关产品信息</span>
                    <span
                        class="show-more-or-hide"
                        v-if="isShowProFoldBtn"
                        @click="isFoldProductionText = !isFoldProductionText">
                        {{isFoldProductionText ? '(更多)': '(收起)'}}
                    </span>
                </div>
            </div>
            <div class="customer-base-info-item customer-content">
                <div class="customer-base-info-title">
                    公司介绍
                </div>
                <div class="customer-base-info-content customer-content-content">{{customer.description || "暂无公司介绍"}}</div>
            </div> -->
        </template>
        <el-form
            ref="baseInfoForm"
            class="customer-info-form"
            :model="baseInfoForm"
            label-width="100px"
            :rules="baseInfoFormRules"
            v-if="isEditBaseInfo"
            v-loading="baseInfoFormLoading">
            <el-form-item
                class="inline-form-item"
                label="公司简称"
                prop="name">
                <el-input v-model="baseInfoForm.name" :maxlength="128" placeholder="常用简称/知名度较高的称呼"></el-input>
            </el-form-item>
            <el-form-item
                class="inline-form-item"
                label="公司全称"
                prop="fullName">
                <el-input v-model="baseInfoForm.fullName" :maxlength="128" placeholder="营业执照上的公司全称"></el-input>
            </el-form-item>
            <!-- <el-form-item
                class="inline-form-item"
                label="所在地点"
                prop="city">
                <el-cascader
                    :props="{expandTrigger: 'hover'}"
                    :options="cities"
                    :show-all-levels="false"
                    :clearable="true"
                    v-model="selectedCity"
                    filterable
                    :disabled="customer.locations && customer.locations.length > 1"
                ></el-cascader>
            </el-form-item> -->

            <el-form-item
                label="所属领域"
                prop="domainId"
                class="inline-form-item"
                v-if="isCFUser">
                <el-cascader
                    :props="{expandTrigger: 'hover', value: 'id', label: 'name', children: 'subLevel'}"
                    :options="domainArray"
                    :show-all-levels="false"
                    :clearable="true"
                    v-model="selectedDomain"
                    @change="handleSelectDomain"
                    filterable
                ></el-cascader>
            </el-form-item>

            <el-form-item
                label="所属行业"
                prop="industry"
                class="inline-form-item">
                <el-cascader
                    :props="{expandTrigger: 'hover', value: 'code', label: 'name', children: 'subLevel'}"
                    :options="industrys"
                    :show-all-levels="false"
                    :clearable="true"
                    v-model="selectedIndustry"
                    @change="handleSelectIndustry"
                    filterable
                ></el-cascader>
            </el-form-item>
            <el-form-item
                label="公司网站"
                prop="webSite"
                class="inline-form-item">
                <el-input v-model="baseInfoForm.webSite"></el-input>
            </el-form-item>
            <el-form-item
                label="公司人数"
                prop="companyScale"
                class="inline-form-item">
                <el-select
                    v-model="baseInfoForm.companyScale"
                    placeholder="请选择"
                    clearable>
                    <el-option
                        v-for="(scale, index) in companyScales"
                        :key="index"
                        :label="scale.text"
                        :value="scale.value">
                        {{scale.text}}
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="公司描述"
                prop="description"
                class="block-form-item">
                <el-input
                    v-model="baseInfoForm.description"
                    type="textarea"
                    :rows="7"
                    :maxlength="2000"
                    show-word-limit
                    placeholder="介绍下公司主要的产品，背景，以及主要创始人的资历"
                ></el-input>
            </el-form-item>
            <el-form-item class="btn-form-item">
                <el-button type="primary" @click="editBaseInfoConfirm">保存</el-button>
                <el-button @click="isEditBaseInfo = false;">取消</el-button>
            </el-form-item>
        </el-form>
        <!-- <div class="customer-base-info-item customer-contact" v-loading="contactLoading">
            <div class="customer-base-info-title">
                客户联系人
                <span class="base-info-title-detail">
                    ({{contactList.length}})
                </span>
                <span class="base-info-operate text-operate-btn" v-if="isCustomerManage" @click="createCusContact">
                    <i class="el-icon-plus"></i>
                    添加联系人
                </span>
            </div>
            <div class="customer-base-info-content customer-contact-list">
                <template v-if="!isCreateContect">
                    <template v-for="(contact, index) in contactList">
                        <div
                            class="customer-contact-item"
                            :key="index"
                            v-if="!contact.isEdit">
                            <span class="contact-item-item contact-item-name">
                                {{contact.name}}
                            </span>
                            <span class="contact-item-item">
                                岗位：
                                <span class="contact-item-item-detail">{{contact.title}}</span>
                            </span>
                            <span class="contact-item-item">
                                电话：
                                <span class="contact-item-item-detail">{{contact.phone}}</span>
                            </span>
                            <span class="contact-item-item">
                                邮箱：
                                <span class="contact-item-item-detail">{{contact.email}}</span>
                            </span>
                            <span class="contact-item-item contact-item-description">
                                描述：
                                <span class="contact-item-item-detail">{{contact.description}}</span>
                            </span>
                            <span class="contact-item-edit-btn text-operate-btn" v-if="isCustomerManage" @click="editContactItem(contact)" style="width: auto;">
                                <i class="el-icon-edit-outline"></i>
                                编辑
                            </span>
                        </div>
                        <el-form
                            :ref="`contactForm-${index}`"
                            :key="index"
                            :model="contactForm"
                            label-width="96px"
                            :rules="contactFormRules"
                            v-if="contact.isEdit"
                            class="customer-info-form">
                            <el-form-item
                                label="姓名"
                                prop="name"
                                class="inline-form-item">
                                <el-input v-model="contactForm.name" :maxlength="128" placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item
                                label="职位"
                                prop="title"
                                class="inline-form-item">
                                <el-input v-model="contactForm.title" :maxlength="128" placeholder="职位"></el-input>
                            </el-form-item>
                            <el-form-item
                                label="电话"
                                prop="phone"
                                class="inline-form-item">
                                <el-input v-model="contactForm.phone" placeholder="电话"></el-input>
                            </el-form-item>
                            <el-form-item
                                label="邮箱"
                                prop="email"
                                class="inline-form-item">
                                <el-input v-model="contactForm.email" placeholder="邮箱"></el-input>
                            </el-form-item>
                            <el-form-item
                                label="描述"
                                prop="description"
                                class="block-form-item">
                                <el-input
                                    v-model="contactForm.description"
                                    :maxlength="200"
                                    type="textarea"
                                    show-word-limit
                                    :rows="4"
                                ></el-input>
                            </el-form-item>
                            <el-form-item class="btn-form-item">
                                <el-button type="primary" @click="editContactItemConfirm(contact, index)">保存</el-button>
                                <el-button @click="contact.isEdit = false;">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </template>
                    <span class="show-more-item-btn" v-if="contactListTotal > contactList.length" @click="getContactList">
                        加载更多
                        <i class="el-icon-arrow-down"></i>
                    </span>
                </template>
                <el-form
                    ref="contactForm"
                    :model="contactForm"
                    label-width="96px"
                    :rules="contactFormRules"
                    v-if="isCreateContect"
                    class="customer-info-form">
                    <el-form-item
                        label="姓名"
                        prop="name"
                        class="inline-form-item">
                        <el-input v-model="contactForm.name" :maxlength="128" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="职位"
                        prop="title"
                        class="inline-form-item">
                        <el-input v-model="contactForm.title" :maxlength="128" placeholder="职位"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="电话"
                        prop="phone"
                        class="inline-form-item">
                        <el-input v-model="contactForm.phone" placeholder="电话"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="邮箱"
                        prop="email"
                        class="inline-form-item">
                        <el-input v-model="contactForm.email" placeholder="邮箱"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="描述"
                        prop="description"
                        class="block-form-item">
                        <el-input
                            v-model="contactForm.description"
                            :maxlength="200"
                            type="textarea"
                            show-word-limit
                            :rows="4"
                        ></el-input>
                    </el-form-item>
                    <el-form-item class="btn-form-item">
                        <el-button type="primary" @click="createContactItemConfirm">保存</el-button>
                        <el-button @click="isCreateContect = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div> -->
        <!-- <div class="customer-base-info-item customer-business-development" v-loading="BDLoading">
            <div class="customer-base-info-title">
                客户开发与业务发展
                <span class="base-info-title-detail">
                    ({{BDList.length}})
                </span>
                <span class="base-info-operate text-operate-btn" v-if="isCustomerManage" @click="createCusBD">
                    <i class="el-icon-plus"></i>
                    添加BD
                </span>
            </div>
            <div class="customer-base-info-content customer-BD-list">
                <template v-if="!isCreateBD">
                    <template v-for="(item, index) in BDList">
                        <div
                            class="customer-BD-item"
                            :key="index"
                            v-if="!item.isEdit">
                            <span class="BD-item-item">
                                用户：{{item.realName}}
                            </span>
                            <span class="BD-item-item">
                                分成比例：{{item.propertion}}%
                            </span>
                            <div class="BD-item-operate-btn-list" v-if="isCustomerManage">
                                <span class="BD-item-edit-btn text-operate-btn" @click="editBDItem(item)">
                                    <i class="el-icon-edit-outline"></i>
                                    编辑
                                </span>
                                <span class="BD-item-edit-btn text-operate-btn" @click="deleteBDtItem(item)">
                                    <i class="el-icon-close"></i>
                                    删除
                                </span>
                            </div>
                        </div>
                        <el-form
                            :ref="`BDForm-${index}`"
                            :key="index"
                            :model="BDForm"
                            label-width="96px"
                            :rules="BDFormRules"
                            v-if="item.isEdit"
                            class="customer-info-form">
                            <el-form-item
                                label="用户"
                                prop="userId"
                                class="inline-form-item">
                                <el-select v-model="BDForm.userId" placeholder="请选择用户">
                                    <el-option
                                        v-for="(member, index) in memberList"
                                        :key="index"
                                        :label="member.realName"
                                        :value="member.userId">
                                        {{member.realName}}
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="分成比例"
                                prop="propertion"
                                :rules="BDFormRules.propertion"
                                class="inline-form-item">
                                <el-input v-model="BDForm.propertion">
                                    <span class="customer-propertion-suffix" slot="suffix">%</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="btn-form-item">
                                <el-button type="primary" @click="editBDItemConfirm(item, index)">保存</el-button>
                                <el-button @click="item.isEdit = false">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </template>
                </template>
                <template>
                    <div v-if="coordinators.length>0" :key="index">
                        综合协调人：
                        <avatar
                            class="accepted-headhunters-avatar"
                            size="md"
                            v-for="item in coordinators"
                            :src="item.avatarId"
                            :userId="item.id"
                            :realName="item.realName"
                            :nickName="item.nickname"
                            :enableCard="true"
                            :enableName="true"
                            :enableLink="true"
                            :showGenCoordinator="true"
                            :key="item.id"
                        ></avatar>
                    </div>
                </template>
                <el-form
                    ref="BDForm"
                    :model="BDForm"
                    label-width="96px"
                    :rules="BDFormRules"
                    v-if="isCreateBD"
                    class="customer-info-form">
                    <el-form-item
                        label="用户"
                        prop="userId"
                        class="inline-form-item">
                        <el-select v-model="BDForm.userId" placeholder="请选择用户">
                            <el-option
                                v-for="(member, index) in memberList"
                                :key="index"
                                :label="member.realName"
                                :value="member.userId">
                                {{member.realName}}
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="分成比例"
                        prop="propertion"
                        :rules="BDFormRules.propertion"
                        class="inline-form-item">
                        <el-input v-model="BDForm.propertion">
                            <span class="customer-propertion-suffix" slot="suffix">%</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="btn-form-item">
                        <el-button type="primary" @click="createBDItemConfirm">保存</el-button>
                        <el-button @click="isCreateBD = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div> -->
        <customer-merge-dialog ref="cusMergeDialog"></customer-merge-dialog>
        <customer-edit-dialog ref="cusEditDialog" @edit-success="handleEditSuccess"></customer-edit-dialog>


        <contract-info-dialog 
            ref="contractInfoDialog"
            @hold-cb="onContractInfoHoldCb"
        />
        <payment-dialog
            ref="paymentDialog"
            @hold-cb="onPaymentHoldCb"
        />


    </div>
</template>

<script>
import cityProvinceMap from '#/js/config/cityProvinceMap.json';
import provinceData from '#/js/config/provinceThreeData.json';
import IndustryData from '#/js/config/industryMap.json';
import { customer as customerUrl } from '#/js/config/api.json';

import CustomerService from '#/js/service/customerService.js';
import { telephone as validTelephone, internationalMobile as validatePhone, email as validateEmail } from '#/js/util/validators.js';
import {
    transformType,
    findChangedKeys,
} from '../config/util';
import {
    entityMap,
    contractTypeMap,
    paymentMap,
    PeriodDateType,
    PeriodStartType,
    PaymentCategory,
    otherPaymentType,
    operationType,
    constractInfoMap,
    paymentKeysMap,
    percentOptions,
} from '../config/config-map';

import CustomerMergeDialog from '../component/customer-merge-dialog.vue';
import CustomerEditDialog from '../component/customer-edit-dialog.vue';
import ContractInfoDialog from '../component/contract-info-dialog.vue';
import PaymentDialog from '../component/payment-dialog.vue';
import Avatar from "#/component/common/avatar.vue";
import LbdEmpty from "#/component/common/lbd-empty.vue";
import moment from 'moment';

export default {
    name: "customerBaseInfo",
    components: {
        CustomerMergeDialog,
        CustomerEditDialog,
        Avatar,
        ContractInfoDialog,
        PaymentDialog,
        LbdEmpty,
    },
    props: {
        customer: Object,
        customerId: String,
    },
    data() {
        return {
            activeName: "customerDepartment",
            // activeName: "customerContract",
            customerBaseInfoTabs: [
                { label: "相关部门", name: "customerDepartment" },
                { label: "相关产品", name: "customerProduction" },
                { label: "公司介绍", name: "customerContent" },
                { label: "客户联系人", name: "customerContact" },
                { label: "客户开发与业务发展", name: "customerBusinessDevelopment" },
                // { label: "客户合同", name: "customerContract" },
            ],
            customerInfoLoading: false,
            isEditBaseInfo: false,
            isFoldDepartmentText: true,
            isFoldProductionText: true,
            baseInfoForm: {
                name: "",
                fullName: "",
                webSite: "",
                companyScale: "",
                description: "",
                industryLevel1Id: "",
                industryId: "",
                domainId: "",
            },
            coordinators: [],
            baseInfoFormRules: {
                name: [{
                    required: true, message: '请填写公司简称', trigger: ['blur', 'change']
                }],
                city: [{
                    required: true, message: '请填写公司所在地点', trigger: ['blur', 'change']
                }],
                domainId: [{
                    required: true, message: '请填写公司所属领域', trigger: ['blur', 'change']
                }],
                webSite: [{
                    validator: (rule, value, callback) => {
                        let regValid = /((http|ftp|https):\/\/)[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
                        if(!value || (value && regValid.test(value))) {
                            callback();
                        } else {
                            callback(new Error('请输入正确的网址'));
                        }
                    },
                    trigger: 'blur'
                }],
            },
            baseInfoFormLoading: false,
            cities: provinceData.province,
            selectedCity: [],
            industrys: IndustryData.industryArray,
            selectedIndustry: [],
            domainArray: [],
            selectedDomain: [],
            companyScales: [
                {
                    text: "少于50人",
                    value: 1
                }, {
                    text: "50-150人",
                    value: 2
                }, {
                    text: "150-500人",
                    value: 3
                }, {
                    text: "500-1000人",
                    value: 4
                }, {
                    text: "1000人以上",
                    value: 5
                }
            ],
            contactLoading: false,
            contactList: [],
            contactForm: {
                name: "",
                title: "",
                phone: "",
                email: "",
                description: ""
            },
            contactFormRules: {
                name: [{
                    required: true, message: '请填写姓名', trigger: ['blur', 'change']
                }],
                phone: [{
                    required: true, message: '请填写电话', trigger: ['blur', 'change']
                }, {
                    validator: this.validatePhone, trigger: 'blur'
                }],
                email: [{
                    required: true, message: '请填写邮箱', trigger: ['blur', 'change']
                }, {
                    validator: this.validateEmail, trigger: 'blur'
                }],
            },
            BDLoading: false,
            BDList: [],
            BDForm: {
                userId: "",
                propertion: ""
            },
            BDFormRules: {
                userId: [{
                    required: true, message: '请选择用户', trigger: ['blur', 'change']
                }],
                propertion: [
                    { required: true, message: '请填写分成比例', trigger: ['blur', 'change'] }, 
                    { validator: this.validatePropertion, trigger: ['blur', 'change'] },
                ],
            },
            memberList: [],
            contactListTotal: 0,
            contactListStart: 0,
            BDListTotal: 0,
            BDListStart: 0,
            isCreateContect: false,
            isCreateBD: false,
            isShowDepFoldBtn: false,
            isShowProFoldBtn: false,


            setAsDefaultPayment: '',
            operationLogData: [],

            originContractInfo: {},
            originPayment: {},
            contractList: [],

            contractStep: 'contractList', // contractList -> contractDetail
            currentContractItem: null,
            contractDetail: {}, // 从合同列表进去的合同详情页面数据
            contractDetailLoading: false,
            contractPaymentList: [], // 合同详情中的支付列表
            operationLogLoading: false,
        };
    },
    filters: {
        industryFilter(val, industryLevel1Id) {
            return val ? IndustryData.industryIdMap[industryLevel1Id][val] : "暂无行业数据";
        }, 
        companyScaleFilter(val) {
            let companyScaleMap = {
                1: "少于50人",
                2: "50-150人",
                3: "150-500人",
                4: "500-1000人",
                5: "1000人以上"
            };
            return val ? companyScaleMap[val] : "暂无人员数据";
        },
        filterSignedEntity(val) {
            return entityMap.find(it => it.value == val)?.label || '未知';
        },
        filterOperationType(val) {
            return operationType.find(it => it.value == val)?.label || '未知';
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isAdministrator(){
            return this.userInfo.isAdministrator;
        },
        isCustomerManage() {
            return this.userInfo.privilegeCodeList?.includes('CustomerCreateAudit') || 
            this.userInfo.privilegeCodeList?.includes('CustomerContractView') || 
            this.userInfo.privilegeCodeList?.includes('CustomerContractManage');
        },
        isCustomerContractManage() {
            return this.userInfo.privilegeCodeList?.includes('CustomerContractManage');
        },
        isCustomerCreateAuditOrView() {
            return this.userInfo.privilegeCodeList?.includes('CustomerContractView') || this.userInfo.privilegeCodeList?.includes('CustomerContractManage');
        },
        isFinancial() {
            return this.userInfo.privilegeCodeList?.includes('Financial');
        },
        isCFUser() {
            return this.userInfo.isCFUser;
        },
        token() {
            return this.$store.state.verificationToken.verificationToken;
        },
        hasBatchEditPermission() {
            let result = false;
            let auditCustomerList = this.$store.state.departmentAudit.customerList || [];
            auditCustomerList.some(item => {
                if(item.id == this.customerId) {
                    result = true;
                    return true;
                }
            })
            return result;
        },
        // 默认付款方式
        defaultPayment() {
            return this.contractPaymentList.find(it => it.paymentCategory == 0) || {};
        },
        // 其它付款方式
        otherPayment() {
            return this.contractPaymentList.filter(it => it.paymentCategory != 0) || [];
        },
        defaultIsEmpty() {
            return JSON.stringify(this.defaultPayment) == '{}';
        },
    },
    mounted() {
        this.getContactList();
        this.getBDList();
        this.getMemberList();
        this.getCoordinators();
        this.getCustomerDomainList();

        this.$nextTick(() => {
            if((this.isCustomerCreateAuditOrView || this.isFinancial || this.isAdministrator) && this.isCFUser) {
                this.getContractList();
                this.customerBaseInfoTabs.push({ label: "客户合同", name: "customerContract" });
            }
        });
    },
    watch: {
        customer: {
            deep: true,
            immediate: true,
            handler: function (val) {
                if(val) {
                    this.setAsDefaultPayment = val.defaultContractId;
                } 
            },
        },
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab.label,tab.name);
            // console.log(this.activeName);
        },
        validatePropertion(rule, value, callback) {
            if(isNaN(+value)) {
                callback(new Error(`请输入合法的比例!`));
            } else {
                // console.log(value);
                if(+value >= 100 || +value<0) {
                    callback(new Error(`比例范围在0-100之间!`));
                }
                if(value && String(value).match(/\.\d*/g)?.[0]?.length>3) {
                    callback(new Error(`最多支持两位小数!`));
                }
                callback();
            }
        },
        // 获取领域
        getCustomerDomainList() {
            CustomerService.customerDomainList().then(res => {
                let domainArrayParent = res.filter(item => !item.parentId);
                let domainArraySub = res.filter(item => !!item.parentId);
                // console.log(domainArrayParent)
                // console.log(domainArraySub)
                
                domainArraySub.forEach(item => {
                    const idx = domainArrayParent.findIndex(pItem => pItem.id == item.parentId);
                    // console.log(idx)
                    if(idx >= 0) {
                        if(domainArrayParent[idx]['subLevel']) {
                            domainArrayParent[idx]['subLevel'].push(item);
                        } else {
                            domainArrayParent[idx]['subLevel'] = [item];
                        }
                    }
                });
                this.domainArray = domainArrayParent;
            });
        },
        // 获取客户的综合协调人
        getCoordinators(){
            this.BDLoading = true;
            _request({
                method: 'GET',
                baseURL: "LbdOpenApi",
                url: customerUrl.get_coordinators.replace(/\%p/ig, this.customerId)
            }).then(res => {
                // console.log(res)
                // res有值就说明有综合协调人
                this.coordinators = res;
                this.BDLoading = false;
            }).catch(err => {
                this.BDLoading = false;
            }).finally(() => {
                this.BDLoading = false;
            });
        },
        getContactList() {
            this.contactLoading = true;
            CustomerService.getCustomerContacts({
                start: this.contactListStart,
                take: 10,
                id: this.customerId
            }).then(res => {
                this.contactLoading = false;
                this.contactListStart += 10;
                this.contactListTotal = res.total;
                res.list.forEach(item => {
                    item.isEdit = false;
                })
                this.contactList = this.contactList.concat(res.list);
            }).catch(err => {
                this.contactLoading = false;
            })
        },
        getBDList() {
            this.BDLoading = true;
            CustomerService.getCustomerBDs({
                start: this.BDListStart,
                take: 10,
                id: this.customerId
            }).then(res => {
                this.BDLoading = false;
                this.BDListStart += 10;
                res.forEach(item => {
                    item.isEdit = false;
                })
                this.BDList = res;
            }).catch(err => {
                this.BDLoading = false;
            })
        },
        getMemberList() {
            _request({
                url: "/MyFirm/TeamMembers",
                method: "GET",
                data: {
                    teamId: 0,
                    take: -1,
                    isShowDimission: false,
                    _: new Date().getTime()
                }
            }).then(res => {
                this.memberList = res.list;
            }).catch(err => {
                console.log(err);
            })
        },
        handleCustomerCreate() {
            this.$refs.cusMergeDialog.show(true, {
                customerId: this.customerId,
                customerName: this.customer.name
            });
        },
        editBaseInfo() {
            let customer = this.customer;

            this.$refs.cusEditDialog.show(customer);


            // let formJson = {
            //     name: customer.name,
            //     fullName: customer.fullName,
            //     webSite: customer.webSite,
            //     companyScale: customer.companyScale,
            //     description: customer.description,
            //     industryLevel1Id: customer.industryLevel1Id,
            //     industryId: customer.industryId,
            //     city: customer.city,
            //     province: cityProvinceMap[customer.city],
            //     domainId: customer.domainId,
            // }
            // this.selectedCity = [cityProvinceMap[customer.city], customer.city];
            // this.selectedIndustry = [customer.industryLevel1Id, customer.industryId];
            // this.selectedDomain = [customer.domainLevel1Id, customer.domainId];
            // Object.assign(this.baseInfoForm, formJson);
            // this.isEditBaseInfo = true;
        },
        handleSelectIndustry(val) {
            this.baseInfoForm.industryLevel1Id = val[0];
            this.baseInfoForm.industryId = val[1];
        },
        handleSelectDomain(val) {
           
            this.baseInfoForm.domainLevel1Id = val[0];
            this.baseInfoForm.domainId = val[1];
        },
        handleEditSuccess(data) {
            this.$emit('refresh');
            Object.assign(this.customer, data);
        },
        editBaseInfoConfirm() {
            this.$refs.baseInfoForm.validate(valid => {
                if(valid) {
                    this.baseInfoFormLoading = true;
                    let json = Object.assign({}, this.baseInfoForm);
                    json.__RequestVerificationToken = this.token;
                    json.id = this.customerId;
                    json.industryLevel1Id = Number(json.industryLevel1Id) || '';
                    json.industryId = Number(json.industryId) || '';
                    json.webSite = json.webSite == '' ? null:json.webSite;
                    if(this.selectedCity[1] != this.customer.city) {
                        json.city = this.selectedCity[1];
                        json.province = this.selectedCity[0];
                    }
                   
                    CustomerService.updateCustomerInfo(json)
                    .then(res => {
                        this.baseInfoFormLoading = false;
                        shortTips("修改成功");
                        this.$emit('refresh');
                        Object.assign(this.customer, this.baseInfoForm);
                        this.isEditBaseInfo = false;
                    }).catch(err => {
                        this.baseInfoFormLoading = false;
                        let errMsg = "";
                        if(err.data) {
                            Object.keys(err.data).some(item => {
                                if(err.data[item].length) {
                                    errMsg = err.data[item][0];
                                    return true;
                                }
                            })
                        }
                        if(!errMsg) {
                            errMsg = err.message || '系统异常，请稍后再试';
                        }
                        shortTips(errMsg)
                    })
                }
            })
        },
        // 点击添加联系人按钮
        createCusContact() {
            this.contactForm = {
                name: "",
                title: "",
                phone: "",
                email: "",
                description: ""
            };
            this.isCreateContect = true;
        },
        // 添加联系人
        createContactItemConfirm() {
            this.$refs.contactForm.validate(valid => {
                if(valid) {
                    let json = Object.assign({}, this.contactForm);
                    // json.__RequestVerificationToken = this.token;
                    json.customerId = this.customerId;
                    this.customerInfoLoading = true;
                    // console.log(json);
                    CustomerService.createCustomerContact(json)
                    .then(res => {
                        this.customerInfoLoading = false;
                        shortTips("添加联系人成功");
                        this.contactListStart = 0;
                        this.contactList = [];
                        this.isCreateContect = false;
                        this.getContactList();
                    }).catch(err => {
                        this.customerInfoLoading = false;
                    })
                }
            })
        },
        // 点击编辑联系人按钮
        editContactItem(contact) {
            this.contactList.forEach(item => {
                item.isEdit = false;
            })
            this.contactForm = Object.assign({}, contact);
            contact.isEdit = true;
        },
        // 手机号验证
        validatePhone(rule, value, callback) {
            if(value && (validTelephone(value) || validatePhone(value))) {
                callback()
            } else {
                callback(new Error('请输入正确的电话号码'));
            }
        },
        // 邮箱验证
        validateEmail(rule, value, callback) {
            if(value && validateEmail(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的邮箱'));
            }
        },
        // 编辑联系人
        editContactItemConfirm(contact, index) {
            this.$refs[`contactForm-${index}`][0].validate(valid => {
                if(valid) {
                    let json = Object.assign({}, this.contactForm);
                    delete json.isEdit
                    // json.__RequestVerificationToken = this.token;
                    this.customerInfoLoading = true;
                    // console.log(json);
                    CustomerService.updateCustomerContact(json)
                    .then(res => {
                        this.customerInfoLoading = false;
                        shortTips("修改成功");
                        Object.assign(contact, this.contactForm);
                        contact.isEdit = false;
                    }).catch(err => {
                        this.customerInfoLoading = false;
                    })
                }
            })
        },
        // 点击添加BD按钮
        createCusBD() {
            this.BDForm = {
                userId: "",
                propertion: ""
            };
            this.isCreateBD = true;
        },
        // 添加BD
        createBDItemConfirm() {
            this.$refs.BDForm.validate(valid => {
                if(valid) {
                    let json = Object.assign({}, this.BDForm);
                    json.customerId = this.customerId;
                    // json.__RequestVerificationToken = this.token;
                    // console.log(json);
                    this.BDLoading = true;
                    CustomerService.createCustomerBD(json)
                    .then(res => {
                        this.BDLoading = false;
                        shortTips("添加BD成功");
                        this.BDListStart = 0;
                        this.BDList = [];
                        this.isCreateBD = false;
                        this.getBDList();
                    }).catch(err => {
                        this.BDLoading = false;
                    })
                }
            })
        },
        // 点击编辑BD按钮
        editBDItem(BDItem) {
            this.BDList.forEach(item => {
                item.isEdit = false;
            })
            this.BDForm = Object.assign({}, BDItem);
            BDItem.isEdit = true;
        },
        // 编辑BD
        editBDItemConfirm(item, index) {
            this.$refs[`BDForm-${index}`][0].validate(valid => {
                if(valid) {
                    let json = Object.assign({}, this.BDForm);
                    delete json.isEdit
                    // delete json.realName
                    json.customerId = this.customerId;
                    // json.__RequestVerificationToken = this.token;
                    this.BDLoading = true;
                    // console.log(json);
                    CustomerService.updateCustomerBD(json)
                    .then(res => {
                        this.BDLoading = false;
                        shortTips("修改成功");
                        // Object.assign(item, this.BDForm);
                        const member = this.memberList.find(item => item.userId == this.BDForm.userId);
                        Object.assign(item, this.BDForm, {
                            realName: member.realName
                        });
                        item.isEdit = false;
                        // this.getBDList();
                    }).catch(err => {
                        this.BDLoading = false;
                    })
                }
            })
        },
        // 删除BD
        deleteBDtItem(BDItem) {
            this.$confirm('确定删除该条BD用户记录?', '提示', {
                type: 'warning',
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false
            }).then(res => {
                this.BDLoading = true;
                CustomerService.deleteCustomerBD(BDItem.id).then(res => {
                    this.BDLoading = false;
                    shortTips('删除成功');
                    this.BDListStart = 0;
                    this.BDList = [];
                    this.getBDList();
                }).catch(err => {
                    this.BDLoading = false;
                })
            }).catch(err => {})
        },
        checkFoldBtn() {
            this.isShowDepFoldBtn = this.customer.departments && this.customer.departments.length && document.querySelector('.customer-department-content') ? document.querySelector('.customer-department-content').scrollHeight > 40 : false;
            this.isShowProFoldBtn = this.customer.products && this.customer.products.length && document.querySelector('.customer-production-content') ? document.querySelector('.customer-production-content').scrollHeight > 40 : false;
        },
        jumpBatchCreateDepartment() {
            window.open(`/Headhunting/MyCompany.html#/batchCreateDepartment/${this.customerId}`);
            // window.open(`/#/batchCreateDepartment/${this.customerId}`);
        },

        // 合同 ----------------- start ---------------------

        onContractInfoHoldCb(type, data) {
            console.log(`onContractInfoHoldCb`, type, data)
            this.originContractInfo = data;
            if(type == 'next') {
                this.$refs.paymentDialog.show('create');
            } else {
                console.log('edit ContractInfo')
                this.updateContract(data);
            }
        },

        onPaymentHoldCb(type, data) {
            console.log(`onPaymentHoldCb`, type, data)
            this.originPayment = data;
            if(type == 'pre') {
                this.$refs.contractInfoDialog.show(false, this.originContractInfo, type);
            } else if(type == 'create') {
                console.log('create')
                this.createContract();
            } else if(type == 'notNow') {
                console.log('notNow')
                this.createContract(type);
            } else if(type == 'edit') {
                console.log('edit')
                this.updatePayment(data);
            } else if(type == 'add') {
                this.createPayment(data);
            }
        },

        // 创建合同 (创建的时候可以带上支付信息)
        // _end = moment(this.activityDateRange[1]).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS");
        createContract(type) {
            const params = {
                ...this.originContractInfo,
                effectiveEndTime: moment(this.originContractInfo.effectiveStartTime[1]).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS"),
                effectiveStartTime: moment(this.originContractInfo.effectiveStartTime[0]).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                // contractType: transformType('contractType', this.originContractInfo.contractType),
                contractType: this.originContractInfo.contractType.join(","),
                customerId: this.customerId,
                signedUserId: this.originContractInfo.signedUserId.join(","),
                attachments: this.originContractInfo.attachments,

                paymentSettings: [this.originPayment.defaultPayment],
            };

            if(this.originContractInfo.subjects.length > 0) {
                params.customerSubject = this.originContractInfo.customerSubject + ',' + this.originContractInfo.subjects.map(it => it.value).join(',');
            }

            if(type == 'notNow') {
                // 暂时不填支付配置
                params.paymentSettings = [];
            } else {
                // 如果有其它支付方式
                if(this.originPayment.otherPayments.length > 0) {
                    params.paymentSettings = params.paymentSettings.concat(this.originPayment.otherPayments);
                }
            }

            console.log(`createContract params:`, params)
            CustomerService
                .contractCreate(params)
                .then(res => {
                    shortTips(`合同创建成功！`)
                    console.log(`contractList:`, res)
                })
                .then(() => {
                    this.getContractList();
                });
        },
        
        // 更新合同 单纯修改合同信息
        updateContract(data) {
            console.log(`updateContract`, data);
            let params = {
                id: this.currentContractItem.id,
                contractNumber: data.contractNumber,
                // customerSubject: data.customerSubject,
                contractType: data.contractType.join(","),
                signedUserId: data.signedUserId.join(","),
                signedEntity: data.signedEntity,
                signedDate: data.signedDate,
                effectiveStartTime: moment(data.effectiveStartTime[0]).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                effectiveEndTime: moment(data.effectiveStartTime[1]).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS"),
                description: data.description,
                attachments: (data.attachments instanceof Array) ? data.attachments : [data.attachments],
            };

            if(data.subjects.length == 0) {
                params.customerSubject = data.customerSubject;
            } else {
                params.customerSubject = data.customerSubject + ',' + data.subjects.map(it => it.value).join(',');
            }

            CustomerService.
                editContractInfo(params)
                .then(res => {
                    console.log(`updateContract----success`, res);
                    shortTips(`合同信息修改成功！`)
                })
                .then(() => {
                    this.getContractDetail(this.currentContractItem.id);
                })
                .finally(() => {

                });
        },
        getContractList() {
            CustomerService
                .contractList(this.customerId)
                .then(res => {
                    console.log(`contractList:`, res)
                    this.contractList = res;
                });
        },

        // 创建支付配置
        createPayment(opts) {
            const params = {
                ...opts.defaultPayment,
                customerContractId: this.currentContractItem.id,

                firstPaymentProportion: +opts.defaultPayment.firstPaymentProportion,
                balancePaymentProportion: +opts.defaultPayment.balancePaymentProportion,
            };
            CustomerService
                .createPayment(params)
                .then(res => {
                    console.log(`createPayment`, res)
                    shortTips(`付款方式添加成功`);
                })
                .then(() => {
                    // this.handleItemDetail(this.currentContractItem);
                    this.getContractPaymentList(this.currentContractItem.id);
                })
                .finally(() => {

                });
        },

        // 更新支付配置
        updatePayment(opts) {
            const params = {
                ...opts.defaultPayment,
                // id: this.currentContractItem.id,
                // customerContractId: this.currentContractItem.id,
            };
            delete params.customerContractId;

            CustomerService
                .editPayment(params)
                .then(res => {
                    // console.log(`createPayment`, res)
                    shortTips(`付款方式更新成功`);
                })
                .then(() => {
                    this.getContractPaymentList(this.currentContractItem.id);
                })
                .finally(() => {

                });
        },
        getContractPaymentList(customerContractId) {
            CustomerService
                .contractPaymentList(customerContractId)
                .then(res => {
                    // console.log(`contractPaymentList`, res)
                    this.contractPaymentList = res;
                })
                .finally(() => {

                });
        },

        onAddContract() {
            this.$refs.contractInfoDialog.show();
        },

        onContractInfoEdit() {
            this.$refs.contractInfoDialog.show(true, this.contractDetail);
        },

        onPaymentAdd() {
            const _label = this.defaultIsEmpty ? 'default': 'other'
            this.$refs.paymentDialog.show('add', _label);
        },
        onPaymentEdit(payIt) {
            // default other
            this.$refs.paymentDialog.show('edit', payIt);
        },
        getContractDetail(contractId) {
            this.contractDetailLoading = true;
            CustomerService
                .contractDetail(contractId)
                .then(res => {
                    // console.log(`contractDetail:`, res)
                    this.contractDetail = res;
                })
                .finally(() => {
                    this.contractDetailLoading = false;
                });
        },
        handleItemDetail(it) {
            this.contractStep = 'contractDetail';
            this.currentContractItem = it;

            this.getContractDetail(it.id);
            this.getContractPaymentList(it.id);
            
        },
        // 补充付款方式
        addPayment(it) {
            this.currentContractItem = it;
            this.$refs.paymentDialog.show('add');
        },
        backContractList() {
            this.contractStep = 'contractList';
            this.getContractList();
        },
        isInValid(it) {
            const _start = moment(it.effectiveStartTime),
            _end = moment(it.effectiveEndTime).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS");
            return (moment().isSameOrAfter(_start) && moment().isSameOrBefore(_end)) || false;
        },
        isUnEffect(it) {
            return moment().isBefore(it.effectiveStartTime);
        },
        isHaveExpried(it) {
            const _end = moment(it.effectiveEndTime).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS");
            return moment().isAfter(_end);
        },
        getTpyeText(type, val) {
            let _label;
            switch (type) {
                case 'overWarrantyPeriodDateType':
                case 'PeriodDateType':
                    _label = PeriodDateType.find(it => it.value == val)?.label;
                    break;
                case 'paymentMethodType':
                    _label = paymentMap.find(it => it.value == val)?.label;
                    break;
                case 'PeriodStartType':
                    _label = PeriodStartType.find(it => it.value == val)?.label;
                    break;
                case 'otherPaymentType':
                    _label = otherPaymentType.find(it => it.value == val)?.label;
                    break;
                case 'PaymentCategory':
                    _label = PaymentCategory.find(it => it.value == val)?.label;
                    break;
                case 'signedEntity':
                    _label = entityMap.find(it => it.value == val)?.label;
                    break;
                case 'contractType':
                    _label = contractTypeMap.find(it => it.value == val)?.label;
                    break;
                default:
                    break;
            }
            return _label;
        },
        setDefaultChange(id) {
            CustomerService
                .changeCustomerDefaultPayment(id)
                .then(res => {
                    shortTips(`默认付款合同修复成功！`)
                    this.$emit('refresh');
                })
                .catch(e => {

                });
        },

        handlePopoverShow() {
            this.getContractOperationLogs(this.currentContractItem.id);
            // this.$nextTick(()=> {
            //     this.$refs.dcPopover.updatePopper() // 注意主要是这里
            // });
        },

        getContractOperationLogs(contractId) {
            this.operationLogLoading = true;
            this.operationLogData = [];
            CustomerService
                .operationLogList(contractId)
                .then(res => {
                    this.operationLogData = res;
                    // console.log(`operationLogList:`, res)
                     this.$nextTick(()=> {
                        this.$refs.dcPopover.updatePopper() // 注意主要是这里
                    });
                })
                .finally(() => {
                    this.operationLogLoading = false;
                });
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        // 生成日志html
        generateLogs(row) {
            var str = '';
            switch (row.operationType) {
                case 0:
                    // 创建合同
                    str = `新建了合同【${row.currentData.contractNumber}】`;
                    break;
                case 1:
                    // 更新合同
                    const changedKeys = findChangedKeys(row.originalData, row.currentData);
                    console.log(`更新合同:`, changedKeys)
                    //  ['contractNumber', 'customerSubject', 'signedUser', 'attachments']
                    // constractInfoMap
                    str = '更新合同：';
                    changedKeys.forEach(it => {
                        if(Object.keys(constractInfoMap).includes(it)) {
                            if(it == 'attachments') {
                                if(row.originalData[it][0].fileName != row.currentData[it][0].fileName) {
                                    str += `${constractInfoMap[it]} 【${row.originalData[it][0].fileName}】->【${row.currentData[it][0].fileName}】;`;
                                }
                            } else if(it == 'effectiveStartTime'){
                                str += `${constractInfoMap[it]} 【${this.formatDate(row.originalData[it])}--${this.formatDate(row.originalData['effectiveEndTime'])}】->【${this.formatDate(row.currentData[it])}--${this.formatDate(row.currentData['effectiveEndTime'])}】;`;
                            } else if(it == 'signedEntity') {
                                str += `${constractInfoMap[it]} 【${this.getTpyeText(it, row.originalData[it])}】->【${this.getTpyeText(it, row.currentData[it])}】;`;
                            } else if(it == 'signedUserId') {
                                str += `${constractInfoMap[it]} 【${row.originalData['signedUser'].realName + '@'}】->【${row.currentData['signedUser'].nickname}】;`;
                            } else if(it == 'contractType') {
                                str += `${constractInfoMap[it]} 【${this.getTpyeText(it, row.originalData[it])}】->【${this.getTpyeText(it, row.currentData[it])}】;`;
                            } else {
                                str += `${constractInfoMap[it]} 【${row.originalData[it]}】->【${row.currentData[it]}】;`;
                            }
                        }
                    });
                    break;
                case 4:
                    // 创建支付配置
                    str = `创建支付配置 【${this.getTpyeText("PaymentCategory", row.currentData.paymentCategory)}】`;
                    break;
                case 5:
                    // 更新支付配置
                    const changedKeys1 = findChangedKeys(row.originalData, row.currentData);
                    console.log(`更新支付配置:`, changedKeys1)
                    str = '更新支付配置：';
                    changedKeys1.forEach(it => {
                        if(Object.keys(paymentKeysMap).includes(it)) {
                            if(it == 'overWarrantyPeriodDateType' || it == 'paymentMethodType') {
                                str += `${paymentKeysMap[it]} 【${this.getTpyeText(it, row.originalData[it])}】->【${this.getTpyeText(it, row.currentData[it])}】;`;
                            } else if(['firstPaymentProportion', 'balancePaymentProportion'].includes(it)){
                                str += `${paymentKeysMap[it]} 【${row.originalData[it]}%】->【${row.currentData[it]}%】;`;
                            } else if(['firstInvoicePeriodStartType', 'firstRefundPeriodStartType', 'balanceInvoicePeriodStartType', 'balanceRefundPeriodStartType'].includes(it)) {
                                str += `${paymentKeysMap[it]} 【${this.getTpyeText('PeriodStartType', row.originalData[it])}】->【${this.getTpyeText('PeriodStartType', row.currentData[it])}】;`;
                            } else if(['firstInvoicePeriodDateType', 'firstRefundPeriodDateType', 'balanceInvoicePeriodDateType', 'balanceRefundPeriodDateType'].includes(it)) {
                                str += `${paymentKeysMap[it]} 【${this.getTpyeText('PeriodDateType', row.originalData[it])}】->【${this.getTpyeText('PeriodDateType', row.currentData[it])}】;`;
                            } else {
                                str += `${paymentKeysMap[it]} 【${row.originalData[it]}】->【${row.currentData[it]}】;`;
                            }
                        }
                    });

                    break;
                case 7:
                    // 更新默认合同
                    if(!row.originalData) {
                        str = `设置了默认合同 【${row.currentData}】`;
                    } else {
                        str = `更新默认合同 【${row.originalData}】->【${row.currentData}】`;
                    }
                    break;
                default:
                    break;
            }
            return str;
        },

       

        // 合同 ----------------- end ---------------------

    }
}
</script>

<style lang="scss" scoped>
    .customer-base-info {
        width: 1200px;
        // border: 1px solid #d3d3d3;
        padding: 18px 16px 16px 24px;
        // border-top: 4px solid $primary;
        // margin-bottom: 36px;
        margin: 0 auto 22px;
        position: relative;

        background-color: #ffffff;
        border-radius: 8px;
        .customer-operate {
            position: absolute;
            // top: 35px;
            top: 18px;
            right: 16px;
            display: flex;
            align-items: center;
            line-height: 22px;

            .customer-top-btn {
                margin-left: 12px;
                height: 42px;
                line-height: 42px;
                font-size: 16px;
                border-radius: 5px;

                &.plain {
                    color: $primary;
                    background-color: #fff;
                    border-color: $primary;
                }
            }

            .text-operate-btn {
                width: 175px;
                height: 63px;
                line-height: 63px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                text-align: center;
                font-size: 14px;
                color: #666666;
                &:hover {
                    background-color: #38BC9D;
                    color: #fff;
                    text-decoration: none;
                }
            }

            .text-operate-btn + .text-operate-btn {
                margin-left: 30px;
            }

            .framework-icon-btn {
                display: flex;
                align-items: center;
            }

            .framework-icon {
                width: 14px;
                height: 14px;
                margin-right: 3px;
            }
        }
        .customer-name {
            width: calc(100% - 440px);
            height: 26px;
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
            color: #333;

            .customer-short-name {
                max-width: 30%;
                font-size: 20px;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .customer-full-name {
                max-width: 70%;
                font-size: 14px;
                margin-left: 10px;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
        }
        .customer-tags {
            span {
                display: inline-block;
                padding: 0 10px;
                margin-bottom: 10px;
                height: 28px;
                margin-right: 5px;
                line-height: 28px;
                color: #999;
                background-color: #F6F6F6;
            }
        }
        .customer-location {
            max-width: 800px;
        }
        .customer-location, .customer-more-detail {
            line-height: 24px;
            margin-bottom: 10px;
        }

        .el-tabs__nav-wrap {
            margin-left: -24px;
            padding-left: 24px;

            &::after {
                height: 1px;
            }
        }

        .customer-info-icon {
            color: #ccc;
            font-size: 16px;
        }
        .customer-more-detail {
            margin-bottom: 20px;
            & > span + span {
                margin-left: 30px;
            }
        }
        .customer-base-info-item {
            // margin-bottom: 32px;
            margin: 15px 0 10px;
            .customer-base-info-btn {
                cursor: pointer;
                margin-bottom: 30px;
                color: #38BC9D;
                font-size: 14px;
            }

            .customer-contact-tip {
                color: #FF4B4B;
                line-height: 20px;
                margin-top: -15px;

                i {
                    margin-right: 4px;
                }
            }

            &.customer-business-development {
                margin-bottom: 0;
            }
            .customer-base-info-title {
                padding: 0;
                margin: 10px 0;
                font-size: 18px;
                color: #333;
                height: 20px;
                line-height: 20px;
                position: relative;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 20px;
                    margin-right: 9px;
                    background-color: $primary;
                    position: relative;
                    top: -2px;
                    vertical-align: middle;
                }
            }
            .base-info-title-detail {
                color: $primary;
            }
            .base-info-operate {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 14px;
                .el-icon-plus {
                    font-weight: bold;
                }
            }
            .customer-base-info-content {
                color: #666;
                font-size: 14px;
                line-height: 20px;
                position: relative;
                overflow: hidden;
                .customer-department-item, .customer-production-item {
                    white-space: nowrap;
                    padding: 0 4px;
                    border-right: 1px solid #666;
                    &:nth-last-of-type(1) {
                        border-right: none;
                    }
                }
                .show-more-or-hide {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: inline-block;
                    width: 56px;
                    line-height: 22px;
                    color: $primary;
                    background-color: #fff;
                    cursor: pointer;
                    text-align: center;
                }
                .show-more-item-btn {
                    margin-top: 20px;
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    line-height: 38px;
                    background-color: #fff;
                    border: 1px solid #d3d3d3;
                    color: #b9b9b9;
                    text-align: center;
                    color: #b9b9b9;
                    cursor: pointer;
                }
                &.customer-content-content {
                    white-space: pre-wrap;
                    word-break: break-all;
                }
            }


            // 合同详情及操作
            .conrtract-wrap{
                .att-file{
                    cursor: pointer;
                    color: $primary;
                    &:hover{
                        // color: $primary;
                    }
                }
                .contract-des{
                    // padding: 0 28px;
                    line-height: 24px;
                    // margin-bottom: 14px;
                    white-space: pre-wrap;
                    font-family: inherit;
                }
                .no-data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        background-size: contain;
                        width: 150px;
                        height: 150px;
                    }
                    .empty-data {
                        line-height: 20px;
                        margin-bottom: 0px;
                    }
                }
                .my-label{
                    width: 260px;
                    color: #666;
                    // background: red;
                }
                .my-content{
                    width: 260px;
                }
                .contract-content-cell{
                    min-width: 240px;
                }
            }

        }
        .customer-info-form.el-form {
            width: 840px;
            .el-form-item__label {
                text-align: left;
                margin-bottom: 0;
            }
            .inline-form-item {
                width: 380px;
                display: inline-block;
                &:nth-of-type(2n) {
                    margin-left: 80px;
                }
                .el-input__inner {
                    width: 280px;
                }
                .el-input__suffix {
                    width: 30px;
                }
            }
        }
        .customer-contact-item {
            margin-bottom: 6px;
            .contact-item-item {
                display: inline-block;
                // width: 200px;
                width: 230px;
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #666;
                .contact-item-item-detail {
                    color: #b9b9b9;
                }
                &.contact-item-description {
                    width: 300px;
                }
                &.contact-item-name {
                    width: 160px;
                }
            }
            .contact-item-edit-btn {
                display: none;
            }
            &:hover {
                .contact-item-edit-btn {
                    display: inline-block;
                }
            }
        }
        .customer-BD-item {
            margin-bottom: 6px;
            .BD-item-item {
                display: inline-block;
                width: 400px;
                padding-right: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #666;
            }
            .BD-item-operate-btn-list {
                display: none;
            }
            .BD-item-edit-btn {
                & + .BD-item-edit-btn {
                    margin-left: 40px;
                }
            }
            &:hover {
                .BD-item-operate-btn-list {
                    display: inline-flex;
                    justify-content: space-between;
                }
            }
        }
        .general-coordinator-item{
            cursor: pointer;
            color:#38BC9D
        }
        .accepted-headhunters-avatar {
            display: inline-block;
            margin-right: 10px;
        }
    }
</style>
