var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.customerInfoLoading,
          expression: "customerInfoLoading",
        },
      ],
      staticClass: "customer-base-info",
    },
    [
      !_vm.isEditBaseInfo
        ? _c(
            "div",
            { staticClass: "customer-operate" },
            [
              _vm.isCustomerManage
                ? _c(
                    "el-button",
                    {
                      staticClass: "customer-top-btn plain",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCustomerCreate },
                    },
                    [_vm._v("合并到另一客户")]
                  )
                : _vm._e(),
              _vm.isCustomerManage
                ? _c(
                    "el-button",
                    {
                      staticClass: "customer-top-btn plain",
                      attrs: { type: "primary" },
                      on: { click: _vm.editBaseInfo },
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _vm.hasBatchEditPermission
                ? _c(
                    "el-button",
                    {
                      staticClass: "customer-top-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.jumpBatchCreateDepartment },
                    },
                    [_vm._v("批量创建组织架构")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "customer-name" }, [
        _c(
          "span",
          {
            staticClass: "customer-short-name",
            attrs: { title: _vm.customer.name },
          },
          [_vm._v("\n            " + _vm._s(_vm.customer.name) + "\n        ")]
        ),
        _c(
          "span",
          {
            staticClass: "customer-full-name",
            attrs: { title: _vm.customer.fullName },
          },
          [_vm._v(_vm._s(_vm.customer.fullName))]
        ),
      ]),
      _c("div", { staticClass: "customer-tags" }, [
        _vm.customer.importantLevel
          ? _c("span", [_vm._v(_vm._s(_vm.customer.importantLevel))])
          : _vm._e(),
        _vm.customer.businessNature
          ? _c("span", [_vm._v(_vm._s(_vm.customer.businessNature))])
          : _vm._e(),
        _vm.customer.developerCount
          ? _c("span", [
              _vm._v("研发" + _vm._s(_vm.customer.developerCount) + "人"),
            ])
          : _vm._e(),
      ]),
      !_vm.isEditBaseInfo
        ? [
            _vm.customer.locations &&
            _vm.customer.locations.length > 0 &&
            _vm.customer.locations[0] !== null
              ? _c("div", { staticClass: "customer-location" }, [
                  _c("i", {
                    staticClass: "el-icon-location-outline customer-info-icon",
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.customer.locations && _vm.customer.locations.length
                          ? _vm.customer.locations.join(" | ")
                          : ""
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "customer-more-detail" }, [
              _c("span", { staticClass: "customer-industry" }, [
                _c("i", {
                  staticClass: "el-icon-price-tag customer-info-icon",
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("industryFilter")(
                        _vm.customer.industryId,
                        _vm.customer.industryLevel1Id
                      )
                    ) +
                    "\n            "
                ),
              ]),
              _c("span", { staticClass: "customer-company-scale" }, [
                _c("i", { staticClass: "el-icon-user customer-info-icon" }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("companyScaleFilter")(_vm.customer.companyScale)
                    ) +
                    "\n            "
                ),
              ]),
              _c("span", { staticClass: "customer-website" }, [
                _c("i", {
                  staticClass: "el-icon-office-building customer-info-icon",
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.customer.webSite || "暂无公司网址") +
                    "\n            "
                ),
              ]),
              _vm.isCFUser
                ? _c("span", { staticClass: "customer-website" }, [
                    _c("i", {
                      staticClass: "el-icon-guide customer-info-icon",
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.customer.domainName || "暂无公司领域") +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.customerBaseInfoTabs, function (tab, index) {
                return _c(
                  "el-tab-pane",
                  {
                    key: "customerBaseInfoTab_" + index,
                    attrs: { name: tab.name },
                  },
                  [
                    _c(
                      "template",
                      { slot: "label" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(tab.label) +
                            "\n                    "
                        ),
                        tab.name == "customerDepartment"
                          ? [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.customer.departments.length) +
                                  ")"
                              ),
                            ]
                          : _vm._e(),
                        tab.name == "customerProduction"
                          ? [
                              _vm._v(
                                "(" + _vm._s(_vm.customer.products.length) + ")"
                              ),
                            ]
                          : _vm._e(),
                        tab.name == "customerContact"
                          ? [_vm._v("(" + _vm._s(_vm.contactListTotal) + ")")]
                          : _vm._e(),
                        tab.name == "customerBusinessDevelopment"
                          ? [_vm._v("(" + _vm._s(_vm.BDList.length) + ")")]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  2
                )
              }),
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerDepartment",
                    expression: "activeName == 'customerDepartment'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "customer-base-info-content customer-department-content",
                    style:
                      _vm.isShowDepFoldBtn && _vm.isFoldDepartmentText
                        ? "height: 40px;"
                        : "",
                  },
                  [
                    _vm._l(
                      _vm.customer.departments,
                      function (department, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "customer-department-item",
                          },
                          [_vm._v(_vm._s(department))]
                        )
                      }
                    ),
                    !_vm.customer.departments ||
                    _vm.customer.departments.length === 0
                      ? _c("span", [_vm._v("暂无相关部门信息")])
                      : _vm._e(),
                    _vm.isShowDepFoldBtn
                      ? _c(
                          "span",
                          {
                            staticClass: "show-more-or-hide",
                            on: {
                              click: function ($event) {
                                _vm.isFoldDepartmentText =
                                  !_vm.isFoldDepartmentText
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.isFoldDepartmentText ? "(更多)" : "(收起)"
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerProduction",
                    expression: "activeName == 'customerProduction'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "customer-base-info-content customer-production-content",
                    style:
                      _vm.isShowProFoldBtn && _vm.isFoldProductionText
                        ? "height: 40px;"
                        : "",
                  },
                  [
                    _vm._l(_vm.customer.products, function (production, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "customer-production-item" },
                        [_vm._v(_vm._s(production))]
                      )
                    }),
                    !_vm.customer.products || _vm.customer.products.length === 0
                      ? _c("span", [_vm._v("暂无相关产品信息")])
                      : _vm._e(),
                    _vm.isShowProFoldBtn
                      ? _c(
                          "span",
                          {
                            staticClass: "show-more-or-hide",
                            on: {
                              click: function ($event) {
                                _vm.isFoldProductionText =
                                  !_vm.isFoldProductionText
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.isFoldProductionText ? "(更多)" : "(收起)"
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerContent",
                    expression: "activeName == 'customerContent'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "customer-base-info-content customer-content-content",
                  },
                  [_vm._v(_vm._s(_vm.customer.description || "暂无公司介绍"))]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerContact",
                    expression: "activeName == 'customerContact'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _c("div", { staticClass: "customer-base-info-btn" }, [
                  _vm._m(0),
                  _c("span", { on: { click: _vm.createCusContact } }, [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(
                      "\n                    添加联系人\n                "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "customer-base-info-content customer-contact-list",
                  },
                  [
                    !_vm.isCreateContect
                      ? [
                          _vm._l(_vm.contactList, function (contact, index) {
                            return [
                              !contact.isEdit
                                ? _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "customer-contact-item",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contact-item-item contact-item-name",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(contact.name) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "contact-item-item" },
                                        [
                                          _vm._v(
                                            "\n                                岗位：\n                                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "contact-item-item-detail",
                                            },
                                            [_vm._v(_vm._s(contact.title))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "contact-item-item" },
                                        [
                                          _vm._v(
                                            "\n                                电话：\n                                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "contact-item-item-detail",
                                            },
                                            [_vm._v(_vm._s(contact.phone))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "contact-item-item" },
                                        [
                                          _vm._v(
                                            "\n                                邮箱：\n                                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "contact-item-item-detail",
                                            },
                                            [_vm._v(_vm._s(contact.email))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contact-item-item contact-item-description",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                描述：\n                                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "contact-item-item-detail",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(contact.description)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contact-item-edit-btn text-operate-btn",
                                          staticStyle: { width: "auto" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editContactItem(
                                                contact
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                          _vm._v(
                                            "\n                                编辑\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              contact.isEdit
                                ? _c(
                                    "el-form",
                                    {
                                      key: index,
                                      ref: `contactForm-${index}`,
                                      refInFor: true,
                                      staticClass: "customer-info-form",
                                      attrs: {
                                        model: _vm.contactForm,
                                        "label-width": "96px",
                                        rules: _vm.contactFormRules,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "姓名",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: 128,
                                              placeholder: "姓名",
                                            },
                                            model: {
                                              value: _vm.contactForm.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactForm,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "contactForm.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "职位",
                                            prop: "title",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: 128,
                                              placeholder: "职位",
                                            },
                                            model: {
                                              value: _vm.contactForm.title,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactForm,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "contactForm.title",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "电话",
                                            prop: "phone",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { placeholder: "电话" },
                                            model: {
                                              value: _vm.contactForm.phone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactForm,
                                                  "phone",
                                                  $$v
                                                )
                                              },
                                              expression: "contactForm.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "邮箱",
                                            prop: "email",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { placeholder: "邮箱" },
                                            model: {
                                              value: _vm.contactForm.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactForm,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "contactForm.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "block-form-item",
                                          attrs: {
                                            label: "描述",
                                            prop: "description",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: 200,
                                              type: "textarea",
                                              "show-word-limit": "",
                                              rows: 4,
                                            },
                                            model: {
                                              value:
                                                _vm.contactForm.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contactForm,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contactForm.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { staticClass: "btn-form-item" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editContactItemConfirm(
                                                    contact,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  contact.isEdit = false
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm.contactListTotal > _vm.contactList.length
                            ? _c(
                                "span",
                                {
                                  staticClass: "show-more-item-btn",
                                  on: { click: _vm.getContactList },
                                },
                                [
                                  _vm._v(
                                    "\n                        加载更多\n                        "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-arrow-down",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.isCreateContect
                      ? _c(
                          "el-form",
                          {
                            ref: "contactForm",
                            staticClass: "customer-info-form",
                            attrs: {
                              model: _vm.contactForm,
                              "label-width": "96px",
                              rules: _vm.contactFormRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: { label: "姓名", prop: "name" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: 128,
                                    placeholder: "姓名",
                                  },
                                  model: {
                                    value: _vm.contactForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.contactForm, "name", $$v)
                                    },
                                    expression: "contactForm.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: { label: "职位", prop: "title" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: 128,
                                    placeholder: "职位",
                                  },
                                  model: {
                                    value: _vm.contactForm.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.contactForm, "title", $$v)
                                    },
                                    expression: "contactForm.title",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: { label: "电话", prop: "phone" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "电话" },
                                  model: {
                                    value: _vm.contactForm.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.contactForm, "phone", $$v)
                                    },
                                    expression: "contactForm.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: { label: "邮箱", prop: "email" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "邮箱" },
                                  model: {
                                    value: _vm.contactForm.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.contactForm, "email", $$v)
                                    },
                                    expression: "contactForm.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "block-form-item",
                                attrs: { label: "描述", prop: "description" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: 200,
                                    type: "textarea",
                                    "show-word-limit": "",
                                    rows: 4,
                                  },
                                  model: {
                                    value: _vm.contactForm.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.contactForm,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "contactForm.description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { staticClass: "btn-form-item" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.createContactItemConfirm },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.isCreateContect = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerBusinessDevelopment",
                    expression: "activeName == 'customerBusinessDevelopment'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _c("div", { staticClass: "customer-base-info-btn" }, [
                  _vm.isCustomerManage
                    ? _c("span", { on: { click: _vm.createCusBD } }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(
                          "\n                    添加BD\n                "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "customer-base-info-content customer-BD-list",
                  },
                  [
                    !_vm.isCreateBD
                      ? [
                          _vm._l(_vm.BDList, function (item, index) {
                            return [
                              !item.isEdit
                                ? _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "customer-BD-item",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "BD-item-item" },
                                        [
                                          _vm._v(
                                            "\n                                用户：" +
                                              _vm._s(item.realName) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "BD-item-item" },
                                        [
                                          _vm._v(
                                            "\n                                分成比例：" +
                                              _vm._s(item.propertion) +
                                              "%\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm.isCustomerManage
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "BD-item-operate-btn-list",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "BD-item-edit-btn text-operate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editBDItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-edit-outline",
                                                  }),
                                                  _vm._v(
                                                    "\n                                    编辑\n                                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "BD-item-edit-btn text-operate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteBDtItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                  _vm._v(
                                                    "\n                                    删除\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              item.isEdit
                                ? _c(
                                    "el-form",
                                    {
                                      key: index,
                                      ref: `BDForm-${index}`,
                                      refInFor: true,
                                      staticClass: "customer-info-form",
                                      attrs: {
                                        model: _vm.BDForm,
                                        "label-width": "96px",
                                        rules: _vm.BDFormRules,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "用户",
                                            prop: "userId",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择用户",
                                              },
                                              model: {
                                                value: _vm.BDForm.userId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.BDForm,
                                                    "userId",
                                                    $$v
                                                  )
                                                },
                                                expression: "BDForm.userId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.memberList,
                                              function (member, index) {
                                                return _c(
                                                  "el-option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label: member.realName,
                                                      value: member.userId,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          member.realName
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "inline-form-item",
                                          attrs: {
                                            label: "分成比例",
                                            prop: "propertion",
                                            rules: _vm.BDFormRules.propertion,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.BDForm.propertion,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.BDForm,
                                                    "propertion",
                                                    $$v
                                                  )
                                                },
                                                expression: "BDForm.propertion",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "customer-propertion-suffix",
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { staticClass: "btn-form-item" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editBDItemConfirm(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  item.isEdit = false
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ]
                      : _vm._e(),
                    [
                      _vm.coordinators.length > 0
                        ? _c(
                            "div",
                            { key: _vm.index },
                            [
                              _vm._v(
                                "\n                        综合协调人：\n                        "
                              ),
                              _vm._l(_vm.coordinators, function (item) {
                                return _c("avatar", {
                                  key: item.id,
                                  staticClass: "accepted-headhunters-avatar",
                                  attrs: {
                                    size: "md",
                                    src: item.avatarId,
                                    userId: item.id,
                                    realName: item.realName,
                                    nickName: item.nickname,
                                    enableCard: true,
                                    enableName: true,
                                    enableLink: true,
                                    showGenCoordinator: true,
                                  },
                                })
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    _vm.isCreateBD
                      ? _c(
                          "el-form",
                          {
                            ref: "BDForm",
                            staticClass: "customer-info-form",
                            attrs: {
                              model: _vm.BDForm,
                              "label-width": "96px",
                              rules: _vm.BDFormRules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: { label: "用户", prop: "userId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择用户" },
                                    model: {
                                      value: _vm.BDForm.userId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.BDForm, "userId", $$v)
                                      },
                                      expression: "BDForm.userId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.memberList,
                                    function (member, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: {
                                            label: member.realName,
                                            value: member.userId,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(member.realName) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-form-item",
                                attrs: {
                                  label: "分成比例",
                                  prop: "propertion",
                                  rules: _vm.BDFormRules.propertion,
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    model: {
                                      value: _vm.BDForm.propertion,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.BDForm, "propertion", $$v)
                                      },
                                      expression: "BDForm.propertion",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "customer-propertion-suffix",
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("%")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { staticClass: "btn-form-item" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.createBDItemConfirm },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.isCreateBD = false
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "customerContract",
                    expression: "activeName == 'customerContract'",
                  },
                ],
                staticClass: "customer-base-info-item",
              },
              [
                _vm.contractStep === "contractList"
                  ? _c(
                      "div",
                      { staticClass: "conrtract-wrap" },
                      [
                        _vm.isCustomerManage
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                },
                                on: { click: _vm.onAddContract },
                              },
                              [_vm._v("添加合同\n                ")]
                            )
                          : _vm._e(),
                        _vm._l(_vm.contractList, function (it, index) {
                          return _c(
                            "el-card",
                            {
                              key: "contract_" + index,
                              staticStyle: { "margin-top": "8px" },
                            },
                            [
                              _c(
                                "el-descriptions",
                                {
                                  attrs: {
                                    title: `合同编号:${it.contractNumber}`,
                                    column: 2,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "extra" }, slot: "extra" },
                                    [
                                      _vm.isFinancial &&
                                      it.id == _vm.setAsDefaultPayment &&
                                      _vm.setAsDefaultPayment != ""
                                        ? _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: it.id,
                                                border: "",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.setAsDefaultPayment,
                                                callback: function ($$v) {
                                                  _vm.setAsDefaultPayment = $$v
                                                },
                                                expression:
                                                  "setAsDefaultPayment",
                                              },
                                            },
                                            [_vm._v("默认付款方式")]
                                          )
                                        : _vm._e(),
                                      _vm.isCustomerManage
                                        ? _c(
                                            "el-radio",
                                            {
                                              attrs: {
                                                label: it.id,
                                                border: "",
                                              },
                                              on: {
                                                change: _vm.setDefaultChange,
                                              },
                                              model: {
                                                value: _vm.setAsDefaultPayment,
                                                callback: function ($$v) {
                                                  _vm.setAsDefaultPayment = $$v
                                                },
                                                expression:
                                                  "setAsDefaultPayment",
                                              },
                                            },
                                            [_vm._v("设为默认付款方式")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleItemDetail(it)
                                            },
                                          },
                                        },
                                        [_vm._v("查看详情")]
                                      ),
                                      !it.hasPaymentSettings &&
                                      !_vm.isHaveExpried(it) &&
                                      _vm.isCustomerManage
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addPayment(it)
                                                },
                                              },
                                            },
                                            [_vm._v("补充付款方式")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "客户主体" } },
                                    [_vm._v(_vm._s(it.customerSubject))]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "签订人" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          it.signedUser?.realName +
                                            "@" +
                                            it.signedUser?.nickname
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "有效期" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            it.effectiveStartTime
                                          )
                                        ) +
                                          " 至 " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              it.effectiveEndTime
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                      _vm.isUnEffect(it)
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "warning",
                                              },
                                            },
                                            [_vm._v("待生效")]
                                          )
                                        : _vm._e(),
                                      _vm.isInValid(it)
                                        ? _c(
                                            "el-tag",
                                            { attrs: { size: "small" } },
                                            [_vm._v("生效中")]
                                          )
                                        : _vm._e(),
                                      _vm.isHaveExpried(it)
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "info",
                                              },
                                            },
                                            [_vm._v("已过期")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "合同附件" } },
                                    _vm._l(it.attachments, function (sit, idx) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: "attachment_" + idx,
                                          attrs: { size: "small" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(sit.fileName) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm.contractList.length == 0
                          ? _c("lbd-empty")
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.contractStep == "contractDetail"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.contractDetailLoading,
                            expression: "contractDetailLoading",
                          },
                        ],
                        staticClass: "conrtract-wrap",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.backContractList },
                          },
                          [
                            _c("i", { staticClass: "el-icon-d-arrow-left" }),
                            _vm._v(" 返回合同列表"),
                          ]
                        ),
                        _c(
                          "el-descriptions",
                          {
                            staticStyle: { "margin-top": "12px" },
                            attrs: {
                              title: "合同信息",
                              column: 3,
                              border: "",
                              "label-class-name": "my-label",
                              "content-class-name": "contract-content-cell",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "extra" }, slot: "extra" },
                              [
                                _vm.isCustomerContractManage ||
                                _vm.isAdministrator
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: { click: _vm.onContractInfoEdit },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "合同编号" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.contractDetail.contractNumber)
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "客户主体" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.contractDetail.customerSubject)
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "合同类别" } },
                              [_vm._v("猎头")]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "签订日期" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.contractDetail.signedDate
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "合同有效期" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      _vm.contractDetail.effectiveStartTime
                                    )
                                  ) +
                                    " ~ " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.contractDetail.effectiveEndTime
                                      )
                                    )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "签约主体" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterSignedEntity")(
                                      _vm.contractDetail.signedEntity
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "签约人" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.contractDetail.signedUser?.realName +
                                      "@" +
                                      _vm.contractDetail.signedUser?.nickname
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "合同附件", span: 2 } },
                              _vm._l(
                                _vm.contractDetail.attachments,
                                function (it, idx) {
                                  return _c(
                                    "span",
                                    {
                                      key: "attachment_" + idx,
                                      staticClass: "att-file",
                                    },
                                    [
                                      _vm._v(_vm._s(it.fileName) + " "),
                                      _c(
                                        "i",
                                        {
                                          staticStyle: {
                                            color: "#999",
                                            "margin-left": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "上传时间：" + _vm._s(it.created)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "el-descriptions-item",
                              { attrs: { label: "合同费率说明", span: 3 } },
                              [
                                _c("pre", {
                                  staticClass: "contract-des",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.contractDetail.description
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-descriptions",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: {
                              title: "付款信息",
                              "label-class-name": "my-label",
                              column: 3,
                              border: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "extra" }, slot: "extra" },
                              [
                                _vm.isCustomerContractManage ||
                                _vm.isAdministrator
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: { click: _vm.onPaymentAdd },
                                      },
                                      [_vm._v("添加")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.defaultIsEmpty
                              ? [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { span: 3 } },
                                    [
                                      _c("div", { staticClass: "no-data" }, [
                                        _c("span", {
                                          staticClass: "empty-img",
                                        }),
                                        _c("p", { staticClass: "empty-data" }, [
                                          _vm._v("暂无数据"),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "付款方式", span: 3 } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-bank-card",
                                      }),
                                      _vm._v(
                                        "\n                            默认付款方式 \n                            "
                                      ),
                                      _vm.isCustomerContractManage ||
                                      _vm.isAdministrator
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                title: "编辑默认付款方式",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onPaymentEdit(
                                                    _vm.defaultPayment
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑付款方式 ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "保证期" } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.defaultPayment
                                              .overWarrantyPeriod
                                          ) +
                                          " 个 \n                            " +
                                          _vm._s(
                                            _vm.getTpyeText(
                                              "overWarrantyPeriodDateType",
                                              _vm.defaultPayment
                                                .overWarrantyPeriodDateType
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-descriptions-item",
                                    {
                                      attrs: {
                                        label: "付款方式",
                                        "content-class-name": "my-content",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.getTpyeText(
                                              "paymentMethodType",
                                              _vm.defaultPayment
                                                .paymentMethodType
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm.defaultPayment.paymentMethodType == 0
                                    ? [
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: "首尾款比",
                                              "content-class-name":
                                                "my-content",
                                            },
                                          },
                                          [_vm._v("无")]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          { attrs: { label: "全款开票时间" } },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .firstInvoicePeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .firstInvoicePeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .firstInvoicePeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: "全款回款时间",
                                              span: 2,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .firstRefundPeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .firstRefundPeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .firstRefundPeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: "首尾款比",
                                              "content-class-name":
                                                "my-content",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.defaultPayment
                                                  .firstPaymentProportion
                                              ) +
                                                "% ：" +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .balancePaymentProportion
                                                ) +
                                                "%"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          { attrs: { label: "首款开票时间" } },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .firstInvoicePeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .firstInvoicePeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .firstInvoicePeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: "首款回款时间",
                                              span: 2,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .firstRefundPeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .firstRefundPeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .firstRefundPeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          { attrs: { label: "尾款开票时间" } },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .balanceInvoicePeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .balanceInvoicePeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .balanceInvoicePeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-descriptions-item",
                                          {
                                            attrs: {
                                              label: "尾款回款时间",
                                              span: 2,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodStartType",
                                                    _vm.defaultPayment
                                                      .balanceRefundPeriodStartType
                                                  )
                                                ) +
                                                "\n                                " +
                                                _vm._s(
                                                  _vm.defaultPayment
                                                    .balanceRefundPeriod
                                                ) +
                                                " \n                                " +
                                                _vm._s(
                                                  _vm.getTpyeText(
                                                    "PeriodDateType",
                                                    _vm.defaultPayment
                                                      .balanceRefundPeriodDateType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                          ],
                          2
                        ),
                        _vm._l(_vm.otherPayment, function (payIt, index) {
                          return _c(
                            "el-descriptions",
                            {
                              key: "other_" + index,
                              staticStyle: { "margin-top": "4px" },
                              attrs: {
                                "label-class-name": "my-label",
                                column: 3,
                                border: "",
                              },
                            },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "付款方式", span: 3 } },
                                [
                                  _c("i", { staticClass: "el-icon-postcard" }),
                                  _vm._v(
                                    "\n                        其他付款方式( " +
                                      _vm._s(
                                        _vm.getTpyeText(
                                          "otherPaymentType",
                                          payIt.paymentCategory
                                        )
                                      ) +
                                      " \n                            "
                                  ),
                                  payIt.paymentCategory == 2
                                    ? [
                                        _vm._v(
                                          "\n                                【" +
                                            _vm._s(payIt.minAnnualSalary) +
                                            "K-" +
                                            _vm._s(payIt.maxAnnualSalary) +
                                            "K】\n                            "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                                职级【" +
                                            _vm._s(payIt.jobGrade) +
                                            "】\n                            "
                                        ),
                                      ],
                                  _vm._v(
                                    "\n                        ) \n                        "
                                  ),
                                  _vm.isCustomerManage
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            title: "编辑其他付款方式",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onPaymentEdit(payIt)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑付款方式 ")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "保证期" } },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(payIt.overWarrantyPeriod) +
                                      " 个 \n                        " +
                                      _vm._s(
                                        _vm.getTpyeText(
                                          "overWarrantyPeriodDateType",
                                          payIt.overWarrantyPeriodDateType
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                {
                                  attrs: {
                                    label: "付款方式",
                                    "content-class-name": "my-content",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.getTpyeText(
                                          "paymentMethodType",
                                          payIt.paymentMethodType
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              payIt.paymentMethodType == 0
                                ? [
                                    _c(
                                      "el-descriptions-item",
                                      {
                                        attrs: {
                                          label: "首尾款比",
                                          "content-class-name": "my-content",
                                        },
                                      },
                                      [_vm._v("无")]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "全款开票时间" } },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.firstInvoicePeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.firstInvoicePeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.firstInvoicePeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      {
                                        attrs: {
                                          label: "全款回款时间",
                                          span: 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.firstRefundPeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.firstRefundPeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.firstRefundPeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-descriptions-item",
                                      {
                                        attrs: {
                                          label: "首尾款比",
                                          "content-class-name": "my-content",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(payIt.firstPaymentProportion) +
                                            "% ：" +
                                            _vm._s(
                                              payIt.balancePaymentProportion
                                            ) +
                                            "%"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "首款开票时间" } },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.firstInvoicePeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.firstInvoicePeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.firstInvoicePeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      {
                                        attrs: {
                                          label: "首款回款时间",
                                          span: 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.firstRefundPeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.firstRefundPeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.firstRefundPeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      { attrs: { label: "尾款开票时间" } },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.balanceInvoicePeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.balanceInvoicePeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.balanceInvoicePeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-descriptions-item",
                                      {
                                        attrs: {
                                          label: "尾款回款时间",
                                          span: 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodStartType",
                                                payIt.balanceRefundPeriodStartType
                                              )
                                            ) +
                                            "\n                            " +
                                            _vm._s(payIt.balanceRefundPeriod) +
                                            " \n                            " +
                                            _vm._s(
                                              _vm.getTpyeText(
                                                "PeriodDateType",
                                                payIt.balanceRefundPeriodDateType
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                            2
                          )
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "dcPopover",
                            attrs: {
                              placement: "right",
                              title: "操作日志",
                              width: "900",
                              trigger: "hover",
                              "open-delay": 600,
                            },
                            on: { show: _vm.handlePopoverShow },
                          },
                          [
                            _vm.isCustomerManage
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-notebook-2",
                                    }),
                                    _vm._v(
                                      " 查看操作日志\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.operationLogLoading,
                                    expression: "operationLogLoading",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "max-height": "350",
                                  data: _vm.operationLogData,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "日期",
                                    prop: "created",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "用户名", width: "220" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(row.creator?.nickname) +
                                                " @ " +
                                                _vm._s(row.creator?.realName) +
                                                "\n                            "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2776146371
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "操作类型", width: "180" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm._f("filterOperationType")(
                                                    row.operationType
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2397018022
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "日志" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.generateLogs(row)) +
                                                "\n                            "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2083140940
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        : _vm._e(),
      _vm.isEditBaseInfo
        ? _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.baseInfoFormLoading,
                  expression: "baseInfoFormLoading",
                },
              ],
              ref: "baseInfoForm",
              staticClass: "customer-info-form",
              attrs: {
                model: _vm.baseInfoForm,
                "label-width": "100px",
                rules: _vm.baseInfoFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "inline-form-item",
                  attrs: { label: "公司简称", prop: "name" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 128,
                      placeholder: "常用简称/知名度较高的称呼",
                    },
                    model: {
                      value: _vm.baseInfoForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfoForm, "name", $$v)
                      },
                      expression: "baseInfoForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inline-form-item",
                  attrs: { label: "公司全称", prop: "fullName" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: 128,
                      placeholder: "营业执照上的公司全称",
                    },
                    model: {
                      value: _vm.baseInfoForm.fullName,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfoForm, "fullName", $$v)
                      },
                      expression: "baseInfoForm.fullName",
                    },
                  }),
                ],
                1
              ),
              _vm.isCFUser
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "inline-form-item",
                      attrs: { label: "所属领域", prop: "domainId" },
                    },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: {
                            expandTrigger: "hover",
                            value: "id",
                            label: "name",
                            children: "subLevel",
                          },
                          options: _vm.domainArray,
                          "show-all-levels": false,
                          clearable: true,
                          filterable: "",
                        },
                        on: { change: _vm.handleSelectDomain },
                        model: {
                          value: _vm.selectedDomain,
                          callback: function ($$v) {
                            _vm.selectedDomain = $$v
                          },
                          expression: "selectedDomain",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "inline-form-item",
                  attrs: { label: "所属行业", prop: "industry" },
                },
                [
                  _c("el-cascader", {
                    attrs: {
                      props: {
                        expandTrigger: "hover",
                        value: "code",
                        label: "name",
                        children: "subLevel",
                      },
                      options: _vm.industrys,
                      "show-all-levels": false,
                      clearable: true,
                      filterable: "",
                    },
                    on: { change: _vm.handleSelectIndustry },
                    model: {
                      value: _vm.selectedIndustry,
                      callback: function ($$v) {
                        _vm.selectedIndustry = $$v
                      },
                      expression: "selectedIndustry",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inline-form-item",
                  attrs: { label: "公司网站", prop: "webSite" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.baseInfoForm.webSite,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfoForm, "webSite", $$v)
                      },
                      expression: "baseInfoForm.webSite",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "inline-form-item",
                  attrs: { label: "公司人数", prop: "companyScale" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.baseInfoForm.companyScale,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseInfoForm, "companyScale", $$v)
                        },
                        expression: "baseInfoForm.companyScale",
                      },
                    },
                    _vm._l(_vm.companyScales, function (scale, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: scale.text, value: scale.value },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(scale.text) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "block-form-item",
                  attrs: { label: "公司描述", prop: "description" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 7,
                      maxlength: 2000,
                      "show-word-limit": "",
                      placeholder:
                        "介绍下公司主要的产品，背景，以及主要创始人的资历",
                    },
                    model: {
                      value: _vm.baseInfoForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.baseInfoForm, "description", $$v)
                      },
                      expression: "baseInfoForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "btn-form-item" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.editBaseInfoConfirm },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isEditBaseInfo = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("customer-merge-dialog", { ref: "cusMergeDialog" }),
      _c("customer-edit-dialog", {
        ref: "cusEditDialog",
        on: { "edit-success": _vm.handleEditSuccess },
      }),
      _c("contract-info-dialog", {
        ref: "contractInfoDialog",
        on: { "hold-cb": _vm.onContractInfoHoldCb },
      }),
      _c("payment-dialog", {
        ref: "paymentDialog",
        on: { "hold-cb": _vm.onPaymentHoldCb },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "customer-contact-tip" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
      _vm._v("客户联系人仅创建者和内控可见"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }