var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.dialogFormVisible,
        "custom-class": "contract-info-dialog",
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        "before-close": _vm.handleBeforeClose,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "conract-header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("div", { staticClass: "step active" }, [_vm._v("合同信息")]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "step" }, [_vm._v("付款方式")]),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "contractForm",
          attrs: { model: _vm.form, rules: _vm.rules, size: "small" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { "label-width": _vm.formLabelWidth, label: "客户" } },
            [
              _c("el-input", {
                staticClass: "limit-width",
                attrs: { autocomplete: "off", disabled: "" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "合同编号",
                prop: "contractNumber",
              },
            },
            [
              _c("el-input", {
                directives: [{ name: "focus", rawName: "v-focus" }],
                staticClass: "limit-width",
                attrs: { autocomplete: "off", maxlength: "20" },
                model: {
                  value: _vm.form.contractNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contractNumber", $$v)
                  },
                  expression: "form.contractNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "客户主体",
                prop: "customerSubject",
              },
            },
            [
              _c("el-input", {
                staticClass: "limit-width",
                attrs: { autocomplete: "off", maxlength: "20" },
                model: {
                  value: _vm.form.customerSubject,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "customerSubject", $$v)
                  },
                  expression: "form.customerSubject",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "text" },
                  on: { click: _vm.addSubject },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._l(_vm.form.subjects, function (domain, index) {
            return _c(
              "el-form-item",
              {
                key: domain.key,
                attrs: {
                  label: "客户主体" + (index + 2),
                  "label-width": _vm.formLabelWidth,
                  prop: "subjects." + index + ".value",
                  rules: {
                    required: true,
                    message: "客户主体不能为空",
                    trigger: "blur",
                  },
                },
              },
              [
                _c("el-input", {
                  staticClass: "limit-width",
                  attrs: { maxlength: "20" },
                  model: {
                    value: domain.value,
                    callback: function ($$v) {
                      _vm.$set(domain, "value", $$v)
                    },
                    expression: "domain.value",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "del",
                    attrs: { icon: "el-icon-remove-outline", type: "text" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.removeSubject(domain)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "合同类别",
                prop: "contractType",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "limit-width",
                  attrs: { multiple: "", placeholder: "请选择合同类别" },
                  model: {
                    value: _vm.form.contractType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "contractType", $$v)
                    },
                    expression: "form.contractType",
                  },
                },
                _vm._l(_vm.contractTypeMap, function (it, index) {
                  return _c("el-option", {
                    key: "opt_" + index,
                    attrs: { label: it.label, value: it.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "签订人",
                prop: "signedUserId",
              },
            },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    "popper-class":
                      "team-member-popover customer-team-member-popover",
                    trigger: "click",
                    width: "700",
                  },
                  model: {
                    value: _vm.teamMemberPopoverVisible,
                    callback: function ($$v) {
                      _vm.teamMemberPopoverVisible = $$v
                    },
                    expression: "teamMemberPopoverVisible",
                  },
                },
                [
                  _c("team-member-select", {
                    ref: "teamSelect",
                    attrs: {
                      isAllMembers: true,
                      isShowDimission: false,
                      isSingleSelect: true,
                      selected: _vm.form.signedUserId,
                      visible: _vm.teamMemberPopoverVisible,
                    },
                    on: { "item-select-cb": _vm.membersSelect },
                  }),
                  _c("el-input", {
                    staticClass: "limit-width",
                    attrs: {
                      slot: "reference",
                      readonly: true,
                      placeholder: "选择签定人",
                      "suffix-icon": "el-icon-arrow-down",
                    },
                    slot: "reference",
                    model: {
                      value: _vm.creators,
                      callback: function ($$v) {
                        _vm.creators = $$v
                      },
                      expression: "creators",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "签订日期",
                prop: "signedDate",
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "limit-width",
                attrs: {
                  placeholder: "请选择合同签订日期",
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.signedDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "signedDate", $$v)
                  },
                  expression: "form.signedDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "合同有效时间",
                prop: "effectiveStartTime",
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "limit-width",
                attrs: {
                  "end-placeholder": "结束日期",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  type: "daterange",
                },
                model: {
                  value: _vm.form.effectiveStartTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "effectiveStartTime", $$v)
                  },
                  expression: "form.effectiveStartTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "签约主体",
                prop: "signedEntity",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "limit-width",
                  attrs: { placeholder: "请选择签约主体" },
                  model: {
                    value: _vm.form.signedEntity,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "signedEntity", $$v)
                    },
                    expression: "form.signedEntity",
                  },
                },
                _vm._l(_vm.entityMap, function (it, index) {
                  return _c("el-option", {
                    key: "opt_" + index,
                    attrs: { label: it.label, value: it.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "合同附件",
                prop: "attachments",
              },
            },
            [
              _c("input", {
                staticClass: "input",
                staticStyle: { position: "absolute", clip: "rect(0 0 0 0)" },
                attrs: {
                  accept: "image/jpeg, image/png, image/gif, application/pdf",
                  id: "attachment",
                  type: "file",
                },
                on: { change: _vm.changeFile },
              }),
              _vm.form.attachments.length == 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.uploadAttachmentLoading,
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.uploadAttachment },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" 上传\n            "),
                    ]
                  )
                : _c("div", { staticClass: "file-cell" }, [
                    _c("div", { staticClass: "icon" }, [
                      _c("i", { staticClass: "el-icon-document-copy" }),
                    ]),
                    _c("div", { staticClass: "file-name" }, [
                      _vm._v(_vm._s(_vm.form.fileName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "opt" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.uploadAttachmentLoading,
                              size: "small",
                              type: "text",
                            },
                            on: { click: _vm.uploadAttachment },
                          },
                          [_vm._v("替换")]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": _vm.formLabelWidth,
                label: "合同费率说明",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 4, maxRows: 6 },
                  maxlength: "200",
                  placeholder: "请输入合同费率说明",
                  "show-word-limit": "",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _vm.isEdit
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTap("edit")
                    },
                  },
                },
                [_vm._v("保 存")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTap("next")
                    },
                  },
                },
                [_vm._v("下一步")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }