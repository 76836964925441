
import {
    entityMap,
    contractTypeMap,
} from './config-map';

export const transformType = (type, val) => {
    let res;
    switch (type) {
        case 'contractType':
            res = val.map(it => contractTypeMap.find(cIt => cIt.value == it)).join(",");
            break;
    }
    return res;
}

export function findChangedKeys(obj1, obj2) {  
    const changedKeys = [];  
    // 遍历obj1的所有属性  
    for (const key in obj1) {  
        // 如果obj2中不存在该属性，或者属性值不同，则将其添加到changedKeys数组中  
        if (!(key in obj2) || obj1[key] !== obj2[key]) {  
            changedKeys.push(key);  
        }  
    }  
  
    // 遍历obj2的所有属性，检查是否有在obj1中不存在的属性  
    for (const key in obj2) {  
        if (!(key in obj1)) {  
            changedKeys.push(key);  
        }  
    }  
  
    return changedKeys;  
}  