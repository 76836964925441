<template>
    <el-dialog
        class="customer-edit-dialog"
        title="编辑客户"
        :visible.sync="dialogVisible"
        width="660px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <el-form class="customer-edit-form" :model="form" :rules="rules" ref="form">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="公司简称" :required="true" prop="name">
                        <el-input  v-model="form.name" placeholder="常用简称/知名度较高的称呼"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="公司全称" prop="fullName">
                        <el-input  v-model="form.fullName" placeholder="营业执照上的公司全称"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- <el-form-item label="所在地点" :required="true">
                <city-select v-if="dialogVisible" v-on:city-select="selectCity"></city-select>
            </el-form-item> -->

            <el-form-item label="详细地址" prop="address">
                <el-input  v-model="form.address" placeholder="输入客户详细地址"></el-input>
            </el-form-item>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="所属行业" prop="industryId">
                        <el-row :gutter="12" class="small-select-row">
                            <el-col :span="12">
                                <el-select v-model="form.industryLevel1Id">
                                    <!-- <el-option label="请选择" value=""></el-option> -->
                                    <el-option v-for="item in industryMap" :label="item.name" :value="item.code" :key="item.code"></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="form.industryId">
                                    <!-- <el-option label="请选择" value=""></el-option> -->
                                    <el-option v-for="item in industrySubTreeMap" :label="item.name" :value="item.code" :key="item.code"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属领域" prop="domainId" v-if="isCFUser">
                        <el-row :gutter="12" class="small-select-row">
                            <el-col :span="12">
                                <el-select v-model="form.domainLevel1Id">
                                    <el-option v-for="item in domainMap" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="form.domainId">
                                    <el-option v-for="item in domainSubMap" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="公司性质" prop="businessNature">
                        <el-select  style="width:100%" v-model="form.businessNature">
                            <el-option label="请选择" value=""></el-option>
                            <el-option 
                                v-for="(nature, index) in natureList" 
                                :label="nature" 
                                :value="nature" 
                                :key="index"
                                ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="分层重要性" prop="importantLevel">
                        <el-select  style="width:100%" v-model="form.importantLevel">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="A" value="A"></el-option>
                            <el-option label="B" value="B"></el-option>
                            <el-option label="C" value="C"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="公司网站" prop="webSite">
                        <el-input  v-model="form.webSite" placeholder=""></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="12">
                        <el-col :span="12">
                            <el-form-item label="公司人数" prop="companyScale">
                                <el-select  style="width:100%" v-model="form.companyScale">
                                    <el-option label="请选择" value=""></el-option>
                                    <el-option 
                                        v-for="(value, key) in companyScaleMap" 
                                        :label="value" 
                                        :value="key" 
                                        :key="'companyScaleMap_' + key"
                                        ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="研发人员数量" prop="developerCount">
                                <el-input v-model.number="form.developerCount" placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="12">
                <el-col :span="24">
                    <el-form-item label="公司描述">
                        <el-input type="textarea" rows="5" maxlength="2000" v-model="form.description" placeholder="介绍下公司主要的产品，背景，以及主要创始人的资历"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
    // import CitySelect from './citySelect.vue';

    import companyScaleMap from '#/js/config/companyScaleNew.json';
    import industryMapObj from '#/js/config/industryMap.json';
    import customerListService from '#/js/service/customerListService.js';
    import CustomerService from '#/js/service/customerService.js';
    import emitter from '#/js/mixins/emitter';
    import eventBus from '#/js/util/event-bus.js';
    import { customer as customerUrl } from '#/js/config/api.json';

    export default {
        name: "customerEdit",
        components: {
            // CitySelect
        },
        mixins: [emitter],
        data() {
            return {
                isCustomerManagePermission: false,
                isCFUser: false,
                dialogVisible: false,
                loading: false,
                domainMap: [],
                natureList: ['国企/央企-上市', '国企/央企-非上市', '民营/私营-上市', '民营/私营-非上市', '外资-上市', '外资-非上市', '合资-上市', '合资-非上市'],
                form: {
                    name: '',
                    fullName: '',
                    // city: '',
                    address: '',
                    industryLevel1Id: '',
                    industryId: '',
                    domainLevel1Id: '',
                    domainId: '',
                    webSite: null,
                    companyScale: '',
                    description: '',
                    businessNature: '',
                    importantLevel: '',
                    developerCount: '',
                },
                rules: {
                    name: [
                        { required: true, validator: this.validateName, trigger: ['blur', 'change'] }
                    ],
                    // city: [
                    //     { required: true, message: '请选择所在城市', trigger: ['blur', 'change'] }
                    // ],
                    webSite: [
                        { type: 'url', message: '网址格式不正确', trigger: ['blur', 'change'] }
                    ],
                    fullName: [
                        { max: 128, message: '公司全称最长支持128个字符', trigger: ['blur', 'change'] }
                    ],
                    address: [
                        { max: 256, message: '详细地址最长支持256个字符', trigger: ['blur', 'change'] }
                    ],
                    commissionValue: [
                        { validator: this.validateCommissionValue, trigger: ['blur', 'change'] }
                    ],
                    industryId: [
                        { required: true, message: '请选择所属行业', trigger: ['blur', 'change'] }
                    ],
                    domainId: [
                        { required: true, message: '请选择所属领域', trigger: ['blur', 'change'] }
                    ],
                    businessNature: [
                        { required: true, message: '请选择公司性质', trigger: ['blur', 'change'] }
                    ],
                    companyScale: [
                        { required: true, message: '请选择公司人数', trigger: ['blur', 'change'] }
                    ],
                    developerCount: [
                        { required: true, message: '请输入研发人员数量', trigger: ['blur', 'change'] },
                        { type: 'number', message: '请输入数字', trigger: ['blur', 'change']},
                        { validator: this.validateDeveloperCount, trigger: ['blur', 'change'] }
                    ],
                },
                scale: '',
                companyScaleMap: companyScaleMap,
                industryMap: industryMapObj.industryArray,
                firmMembers: []
            }
        },
        watch: {
            'form.industryLevel1Id': function() {
                this.form.industryId = '';
            },
            'form.domainLevel1Id': function() {
                this.form.domainId = '';
            },
            // domainMap(val){
            //     console.log('执行了--------------')
            //     console.log(val)
            // }
        },
        computed: {
            industrySubTreeMap() {
                let arr = [];
                this.industryMap.forEach(item => {
                    if(item.code == this.form.industryLevel1Id) {
                        arr = item.subLevel;
                    }
                })
                return arr;
            },
            domainSubMap() {
                let arr = [];
                this.domainMap.forEach(item => {
                    if(item.value == this.form.domainLevel1Id) {
                        arr = item.children;
                    }
                })
                return arr;
            },
            token(){
                return this.$store.state.verificationToken.verificationToken;
            }
        },
        mounted() {
            this.isCustomerManagePermission = this.$store.state.user.userInfo.privilegeCodeList.some(el=>{
                return el=='CustomerManage';
            })
            this.isCFUser = this.$store.state.user.userInfo.isCFUser;
            // console.log(this.isCFUser);

            // 注意this指向
            // var self = this;
            // eventBus.$on('domainMap',function(data){
            //     // console.log(data);
            //     self.domainMap = data;
            //     // console.log(self.domainMap);
            // })
            // // eventBus.$on('domainMap',(data)=>{
            // //     // console.log(data);
            // //     this.domainMap = data;
            // //     // console.log(this.domainMap);
            // // })

            this.getFirmMembers();
        },
        methods: {
            show(customer) {
                this.form = Object.assign(this.form, customer);
                this.form.companyScale = customer.companyScale ? customer.companyScale.toString() : '';
                this.$nextTick(() => {
                    this.form.domainId = customer.domainId;
                    this.form.industryId = customer.industryId;

                    if (customer.addresses && customer.addresses.length > 0) {
                        this.form.address = customer.addresses[0];
                    }
                })
                this.dialogVisible = true;
                this.getCustomerDomain();
            },
            // 获取领域设置列表
            getCustomerDomain() {
                this.loading = true;
                _request({
                    url: customerUrl.get_customer_domain,
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                    // data: {}
                }).then(res => {
                    // console.log(res);
                    this.onlyOneLevelDomain = res.filter(item => {
                        return item.parentId == '';
                    });
                    // console.log(this.onlyOneLevelDomain);

                    // 过滤一级领域数据
                    this.oneLevelDomainList = res.filter(item => {
                        return item.parentId == '';
                    }).map(v => {
                        return {
                            value: v.id,
                            label: v.name,
                            firmId: v.firmId,
                            parentId: v.parentId,
                            children: []
                        }
                    })
                    // 过滤二级领域数据
                    this.secondLevelDomainList = res.filter(item => {
                        return item.parentId != '';
                    }).map(v => {
                        return {
                            value: v.id,
                            label: v.name,
                            firmId: v.firmId,
                            parentId: v.parentId,
                        }
                    })
                    // console.log(this.oneLevelDomainList);
                    // console.log(this.secondLevelDomainList);

                    // 把对应二级领域的数据放在一级领域的children属性里面
                    this.domainMap = this.oneLevelDomainList.map(one => {
                        // console.log('一级-----------')
                        // console.log(one)
                        this.secondLevelDomainList.some(second => {
                            // console.log('二级-----------')
                            // console.log(second)
                            if(one.value == second.parentId) {
                                // console.log('执行了')
                                one.children.push(second);
                            }
                        })
                        return one;
                    })
                    // console.log(this.domainMap);
                }).finally(() =>{
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                })
            },
            reset() {
                this.$refs.form.resetFields();
                this.form = {
                    name: '',
                    fullName: '',
                    // city: '',
                    address: '',
                    industryLevel1Id: '',
                    industryId: '',
                    domainLevel1Id: '',
                    domainId: '',
                    businessNature: '',
                    importantLevel: '',
                    developerCount: '',
                    webSite: null,
                    companyScale: '',
                    description: '',
                };
            },
            validateMobile(rule, value, callback) {
                let regexMobileExternal = /^(\+|00)\d{8,15}$/,
                    regexMobileInternal = /^(1[3-9])\d{9}$/;
                let arr = value.match(regexMobileExternal) || value.match(regexMobileInternal);
                if(arr === null) {
                    callback(new Error('请填写有效的国内/国际手机号码'));
                } else {
                    callback();
                }
            },
            selectCity(json) {
                this.form.city = json.city;
            },
            cancelHandle() {
                this.reset();
                this.dialogVisible = false;
            },
            confirmHandle() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let data = Object.assign({}, this.form, {
                            __RequestVerificationToken: this.token
                        })

                        delete data.customerBDs;
                        data.webSite = data.webSite == '' ? null : data.webSite;

                        this.loading = true;

                        CustomerService.updateCustomerInfo(data)
                            .then(res => {
                                this.loading = false;
                                this.dialogVisible = false;
                                shortTips("修改成功");
                                this.$emit('edit-success', data);
                            }).catch(err => {
                                this.loading = false;
                            })
                    } else {
                        return false;
                    }
                });
            },
            getFirmMembers() {
                customerListService.getFirmMembers({
                    teamId: 0,
                    take: -1,
                    isShowDimission: false,
                    _: new Date().getTime()
                }).then(res =>{
                    this.firmMembers = res.list;
                })
            },
            validateDeveloperCount(rule, value, callback) {
                let regex = /^[0-9]*[1-9][0-9]*$/;
                if(!regex.test(value)) {
                    callback(new Error('请输入正整数'));
                } else {
                    callback();
                }
            },
            validateName(rule, value, callback) {
                if (value === '') {
                    callback(new Error('请输入公司简称'));
                } else if (value.length > 128) {
                    callback(new Error('公司全称最长支持128个字符!'));
                } else {
                    callback();
                }
            },
            validateCommissionValue(rule, value, callback) {
                if(isNaN(+value)) {
                    callback(new Error(`请输入合法的比例!`));
                } else {
                    if(+value >= 100 || +value<0) {
                        callback(new Error(`比例范围在0-100之间!`));
                    }
                    if(value && String(value).match(/\.\d*/g)?.[0]?.length>3) {
                        callback(new Error(`最多支持两位小数!`));
                    }
                    callback();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .customer-edit-dialog /deep/ .el-dialog {
        .el-dialog__body {
            padding-right: 0;
        }
    }
    .customer-edit-form {
        padding-right: 20px;
        max-height: 640px;
        overflow: hidden;
        overflow-y: auto;

        label {
            text-align: left;
        }

        /deep/ .el-form-item {
            margin-bottom: 16px;

            .el-input__inner {
                height: 38px;
                line-height: 38px;
            }

            .small-select-row::before {
                content: '';
                clear: both;
            }

            .el-form-item__label {
                line-height: 20px;
                padding-bottom: 4px;
            }

            &.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
                content: '';
                display: none;
            }

            &.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
                content: '*';
                color: #F56C6C;
                margin-left: 4px;
            }
        }


        .add-bd-btn {
            font-size: 22px;
            color: $primary;
            margin-left: 15px;
            margin-top: 10px;
        }

        /deep/ .bd-item-outer .el-form-item__label {
            width: 100%;
            text-align: left;
        }

        .el-form-item-bd {
            margin-bottom: 12px;

            .el-select {
                width: 100%;
            }
        }
    }
</style>
