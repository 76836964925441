<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogFormVisible"
        custom-class="payment-dialog"
        @before-close="handleBeforeClose"
        width="700px"
    >
        <div
            class="conract-header"
            slot="title"
        >
            <div class="step">合同信息</div>
            <div class="line"></div>
            <div class="step active">付款方式</div>
        </div>

        <!-- 默认付款方式: -->
        <el-card
            class="box-card"
            shadow="never"
        >
            <div
                class="card-top"
                slot="header"
            >
                <div class="ht-cell">
                    <template v-if="payType == 'other'">
                        <span>其它付款方式:</span>
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            :disabled="operationType == 'edit'"
                            v-model="defaultPayment.paymentCategory"
                        >
                            <el-option
                                :key="'payment_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in otherPaymentType"
                            ></el-option>
                        </el-select>
                    </template>

                    <span v-else>默认付款方式:</span>
                </div>
                <div class="ht-cell">
                    <!-- <el-button
                        icon="el-icon-plus"
                        type="text"
                    >添加</el-button>-->
                </div>
            </div>

            <div class="card-head">
                <el-row v-if="payType == 'other'">

                    <template v-if="defaultPayment.paymentCategory == 1">
                        <el-col :span="6">职级范围:</el-col>
                        <el-col :span="6">
                            <el-autocomplete
                            class="inline-input"
                            v-model="defaultPayment.jobGrade"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入职级"
                            size="small"
                            @select="handleSelect"
                            ></el-autocomplete>
                        </el-col>
                    </template>

                    <template v-if="defaultPayment.paymentCategory == 2">
                        <el-col :span="6">薪资范围:</el-col>
                        <el-col :span="6">
                            <el-input size="small"
                                class="salary-ipt"
                                v-model="defaultPayment.minAnnualSalary" 
                                placeholder="年薪最小值">
                                <button slot="append" class="append-btn">K</button>
                            </el-input>
                        </el-col>
                        <el-col :span="1" class="salary-space">-</el-col>
                        <el-col :span="6">
                            <el-input size="small" 
                                class="salary-ipt"
                                v-model="defaultPayment.maxAnnualSalary" 
                                placeholder="年薪最大值">
                                <button slot="append" class="append-btn">K</button>
                            </el-input>
                        </el-col>
                        <el-col :span="5"></el-col>
                    </template>
                    
                </el-row>
             
                <el-row>
                    <el-col :span="6">保证期:</el-col>
                    <el-col :span="18">
                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.overWarrantyPeriod"
                        ></el-input-number>
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.overWarrantyPeriodDateType"
                        >
                            <el-option
                                :key="'payment_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">付款方式:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.paymentMethodType"
                        >
                            <el-option
                                :key="'payment_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in paymentMap"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row v-if="defaultPayment.paymentMethodType == 1">
                    <el-col :span="6">首尾款比例:</el-col>
                    <el-col :span="18">
                        <!-- <el-input
                            class="width30"
                            placeholder="首款"
                            size="small"
                            v-model="defaultPayment.firstPaymentProportion"
                            @change="handleFirstPaymentChange"
                        >
                            <template slot="append">%</template>
                        </el-input> -->
                        <el-select 
                            class="width30"
                            size="small"
                            @change="handleFirstPaymentChange"
                            v-model="defaultPayment.firstPaymentProportion" 
                            placeholder="请选择">
                            <el-option
                                v-for="item in percentOptions"
                                :key="'opt_' + item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>

                        <el-input
                            class="width30"
                            placeholder="尾款"
                            size="small"
                            readonly
                            v-model="defaultPayment.balancePaymentProportion"
                        >
                            <template slot="append">%</template>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <template v-if="defaultPayment.paymentMethodType == 0">
                <el-divider content-position="left">
                    <i class="el-icon-document-copy"></i> 全款
                </el-divider>
                <el-row>
                    <el-col :span="6">开票时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">回款时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-else>
                <el-divider content-position="left">
                    <i class="el-icon-document-checked"></i> 首款
                </el-divider>
                <el-row>
                    <el-col :span="6">开票时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstInvoicePeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">回款时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.firstRefundPeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>

                <el-divider content-position="left">
                    <i class="el-icon-document-add"></i> 尾款
                </el-divider>
                <el-row>
                    <el-col :span="6">开票时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.balanceInvoicePeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.balanceInvoicePeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.balanceInvoicePeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">回款时间:</el-col>
                    <el-col :span="18">
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.balanceRefundPeriodStartType"
                        >
                            <el-option
                                :key="'PeriodStartType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodStartType"
                            ></el-option>
                        </el-select>

                        <el-input-number
                            :max="90000"
                            :min="1"
                            @change="handleChange"
                            class="width30"
                            label="描述文字"
                            size="small"
                            v-model="defaultPayment.balanceRefundPeriod"
                        ></el-input-number>

                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="defaultPayment.balanceRefundPeriodDateType"
                        >
                            <el-option
                                :key="'PeriodDateType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in PeriodDateType"
                            ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
        </el-card>

        <template v-if="operationType == 'create'">
            <div class="mid-cell">
                <el-button
                    @click="addPayment"
                    icon="el-icon-plus"
                    size="small"
                    type="primary"
                >添加其它付款方式</el-button>
            </div>

            <!-- 其它付款方式 -->
            <el-card
                :key="'otherPayments_' + index"
                class="box-card"
                shadow="never"
                style="margin-top: 30px;"
                v-for="(payItem, index) in otherPayments"
            >
                <div
                    class="card-top"
                    slot="header"
                >
                    <div class="ht-cell">
                        <span>其它付款方式:</span>
                        <el-select
                            class="width30"
                            placeholder="请选择"
                            size="small"
                            v-model="payItem.paymentCategory"
                        >
                            <el-option
                                :key="'otherPaymentType_' + index"
                                :label="it.label"
                                :value="it.value"
                                v-for="(it, index) in otherPaymentType"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="ht-cell opt">
                        <!-- v-if="otherPayments.length>1" -->
                        <el-button
                            class="del"
                            icon="el-icon-remove-outline"
                            size="small"
                            type="text"
                            @click="handleOtherItemDelete(index)"
                        >删除</el-button>
                    </div>
                </div>

                <div class="card-head">
                    <el-row>

                        <template v-if="payItem.paymentCategory == 1">
                            <el-col :span="6">职级范围:</el-col>
                            <el-col :span="6">
                                <el-autocomplete
                                class="inline-input"
                                v-model="payItem.jobGrade"
                                :fetch-suggestions="querySearch"
                                placeholder="请输入职级"
                                size="small"
                                @select="handleSelect"
                                ></el-autocomplete>
                            </el-col>
                        </template>

                        <template v-if="payItem.paymentCategory == 2">
                            <el-col :span="6">薪资范围:</el-col>
                            <el-col :span="6">
                                <el-input size="small" 
                                    class="salary-ipt"
                                    v-model="payItem.minAnnualSalary" 
                                    placeholder="年薪最小值">
                                    <button slot="append" class="append-btn">K</button>
                                </el-input>
                            </el-col>
                            <el-col :span="1" class="salary-space">-</el-col>
                            <el-col :span="6">
                                <el-input size="small" 
                                    class="salary-ipt"
                                    v-model="payItem.maxAnnualSalary" 
                                    placeholder="年薪最大值">
                                    <button slot="append" class="append-btn">K</button>
                                </el-input>
                            </el-col>
                        </template>


<!-- 
                        <el-col :span="20">
                            <el-select
                                class="width30"
                                placeholder="职级选择(可多选)"
                                size="small"
                                v-model="value"
                            >
                                <el-option
                                    :key="'payment_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4"></el-col> -->
                    </el-row>

                    <el-row>
                        <el-col :span="6">保证期:</el-col>
                        <el-col :span="18">
                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.overWarrantyPeriod"
                            ></el-input-number>
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.overWarrantyPeriodDateType"
                            >
                                <el-option
                                    :key="'payment_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">付款方式:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.paymentMethodType"
                            >
                                <el-option
                                    :key="'payment_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in paymentMap"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row v-if="payItem.paymentMethodType == 1">
                        <el-col :span="6">首尾款比例:</el-col>
                        <el-col :span="18">
                            <!-- <el-input
                                class="width30"
                                placeholder="首款"
                                size="small"
                                v-model="payItem.firstPaymentProportion"
                                @change="handleItemFirstPaymentChange(payItem)"
                            >
                                <template slot="append">%</template>
                            </el-input> -->
                            <el-select 
                                class="width30"
                                size="small"
                                @change="handleItemFirstPaymentChange"
                                v-model="payItem.firstPaymentProportion" 
                                placeholder="请选择">
                                <el-option
                                    v-for="item in percentOptions"
                                    :key="'opt_' + item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-input
                                class="width30"
                                placeholder="尾款"
                                size="small"
                                readonly
                                v-model="payItem.balancePaymentProportion"
                            >
                                <template slot="append">%</template>
                            </el-input>
                        </el-col>
                    </el-row>
                </div>

                <template v-if="payItem.paymentMethodType == 0">
                    <el-divider content-position="left">
                        <i class="el-icon-document-copy"></i> 全款
                    </el-divider>
                    <el-row>
                        <el-col :span="6">开票时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstInvoicePeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.firstInvoicePeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstInvoicePeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">回款时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstRefundPeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.firstRefundPeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstRefundPeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </template>
                <template v-else>
                    <el-divider content-position="left">
                        <i class="el-icon-document-checked"></i> 首款
                    </el-divider>
                    <el-row>
                        <el-col :span="6">开票时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstInvoicePeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.firstInvoicePeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstInvoicePeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">回款时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstRefundPeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.firstRefundPeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.firstRefundPeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>

                    <el-divider content-position="left">
                        <i class="el-icon-document-add"></i> 尾款
                    </el-divider>
                    <el-row>
                        <el-col :span="6">开票时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.balanceInvoicePeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.balanceInvoicePeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.balanceInvoicePeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">回款时间:</el-col>
                        <el-col :span="18">
                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.balanceRefundPeriodStartType"
                            >
                                <el-option
                                    :key="'PeriodStartType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodStartType"
                                ></el-option>
                            </el-select>

                            <el-input-number
                                :max="90000"
                                :min="1"
                                @change="handleChange"
                                class="width30"
                                label="描述文字"
                                size="small"
                                v-model="payItem.balanceRefundPeriod"
                            ></el-input-number>

                            <el-select
                                class="width30"
                                placeholder="请选择"
                                size="small"
                                v-model="payItem.balanceRefundPeriodDateType"
                            >
                                <el-option
                                    :key="'PeriodDateType_' + index"
                                    :label="it.label"
                                    :value="it.value"
                                    v-for="(it, index) in PeriodDateType"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </template>
            </el-card>
        </template>

        <div
            class="dialog-footer"
            slot="footer"
        >
            <el-row
                justify="space-between"
                type="flex"
            >
                <el-col
                    :span="10"
                    class="pre-wrap"
                >
                    <el-button
                        @click="handleTap('pre')"
                        v-if="operationType == 'create'"
                    >上一步</el-button>
                </el-col>
                <el-col :span="10">
                    <el-tooltip
                        content="付款方式可不填写先完成合同创建,之后在合同详情中再补充"
                        effect="light"
                        placement="left"
                        v-if="operationType == 'create'"
                    >
                        <el-button
                            @click="handleTap('notNow')"
                            type="text"
                        >暂不填写</el-button>
                    </el-tooltip>

                    <el-button
                        @click="handleTap('edit')"
                        type="primary"
                        v-if="operationType == 'edit'"
                    >保 存</el-button>
                    <el-button
                        @click="handleTap('add')"
                        type="primary"
                        v-if="operationType == 'add'"
                    >补充付款方式</el-button>
                    <el-button
                        @click="handleTap('create')"
                        type="primary"
                        v-if="operationType == 'create'"
                    >创 建</el-button>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import {
    entityMap,
    contractTypeMap,
    paymentMap,
    PeriodDateType,
    PeriodStartType,
    PaymentCategory,
    otherPaymentType,
    percentOptions,
} from '../config/config-map';
import CustomerService from '#/js/service/customerService.js';

export default {
    name: 'payment',
    components: {
    },
    data () {
        return {
            dialogFormVisible: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px',

            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            input2: '',
            num: '',




            paymentMap: paymentMap,
            PeriodDateType: PeriodDateType,
            PeriodStartType: PeriodStartType,
            PaymentCategory: PaymentCategory,
            otherPaymentType: otherPaymentType,

            paymentSettings: [],

            defaultPayment: {
                paymentMethodType: 0, // 付款方式
                paymentCategory: 0,
                jobGrade: '',
                minAnnualSalary: 0,
                maxAnnualSalary: 0,
                overWarrantyPeriodStartType: 0, // 目前没有用
                overWarrantyPeriod: 0,  // 保证期
                overWarrantyPeriodDateType: 0,
                firstPaymentProportion: 10,
                balancePaymentProportion: 90,

                firstInvoicePeriodStartType: 0, // first 全款，首款 balance尾款
                firstInvoicePeriod: 0,
                firstInvoicePeriodDateType: 0, // 开票

                firstRefundPeriodStartType: 0, // 回款
                firstRefundPeriod: 0,
                firstRefundPeriodDateType: 0,

                balanceInvoicePeriodStartType: 0,
                balanceInvoicePeriod: 0,
                balanceInvoicePeriodDateType: 0,
                balanceRefundPeriodStartType: 0,
                balanceRefundPeriod: 0,
                balanceRefundPeriodDateType: 0,
            },
            otherPayments: [
                // {
                //     paymentMethodType: 0, // 付款方式
                //     paymentCategory: 1,
                //     jobGrade: '',
                //     minAnnualSalary: 0,
                //     maxAnnualSalary: 0,
                //     overWarrantyPeriodStartType: 0, // 目前没有用
                //     overWarrantyPeriod: 0,  // 保证期
                //     overWarrantyPeriodDateType: 0,
                //     firstPaymentProportion: 10,
                //     balancePaymentProportion: 0,

                //     firstInvoicePeriodStartType: 0, // first 全款，首款 balance尾款
                //     firstInvoicePeriod: 0,
                //     firstInvoicePeriodDateType: 0, // 开票

                //     firstRefundPeriodStartType: 0, // 回款
                //     firstRefundPeriod: 0,
                //     firstRefundPeriodDateType: 0,

                //     balanceInvoicePeriodStartType: 0,
                //     balanceInvoicePeriod: 0,
                //     balanceInvoicePeriodDateType: 0,
                //     balanceRefundPeriodStartType: 0,
                //     balanceRefundPeriod: 0,
                //     balanceRefundPeriodDateType: 0,
                // },
            ],
            // isEdit: false,
            // isAdd: false, // 补充付款方式（第一次进来是创建）
            operationType: 'create', // add edit
            payType: '', // 其它付款方式
            otherPayment: '', // 其它付款方式(页面内)

            customerJobLevels: [], 
            percentOptions: percentOptions,

        };
    },
    computed: {

    },
    created () {
        this.getCustomerJobLevel();
     },
    mounted () { },
    methods: {
        show (operationType = 'create', payInfo = {}) {
            this.operationType = operationType;
            
            if(operationType == 'add') {
                this.defaultPayment = this.$options.data().defaultPayment;
                if(payInfo == 'other') {
                    // 添加其它付款方式
                    this.payType = 'other';
                    this.defaultPayment.paymentCategory = 1;
                } else {
                    this.payType = '';
                    this.defaultPayment.paymentCategory = 0;
                }
            }

            if(operationType == 'edit') {
                console.log(`payInfo----------------`, payInfo)
                if(payInfo.paymentCategory === 0) {
                    this.payType = '';
                } else {
                    this.payType = 'other';
                }
                Object.assign(this.defaultPayment, payInfo);
            }
            if(operationType == 'create') {
                this.defaultPayment = this.$options.data().defaultPayment;

                this.payType = '';
                this.defaultPayment.paymentCategory = 0;
            }

            this.dialogFormVisible = true;
        },

        getCustomerJobLevel() {
            CustomerService
                .getCustomerJobLevel(this.$route.params.customerId)
                .then(res => {
                    console.log(`getCustomerJobLevel------`, res);
                    this.customerJobLevels = res.list || [];
                });
        },
        handleChange () {

        },
        addPayment () {
            if (this.otherPayments.length == 2) {
                return shortTips(`除了默认付款方式，最多额外添加2种付款方式`);
            };
            const item = {
                paymentMethodType: 0, // 付款方式
                paymentCategory: 1,
                jobGrade: '',
                minAnnualSalary: 0,
                maxAnnualSalary: 0,
                overWarrantyPeriodStartType: 0, // 目前没有用
                overWarrantyPeriod: 0,  // 保证期
                overWarrantyPeriodDateType: 0,
                firstPaymentProportion: 10,
                balancePaymentProportion: 90,

                firstInvoicePeriodStartType: 0, // first 全款，首款 balance尾款
                firstInvoicePeriod: 0,
                firstInvoicePeriodDateType: 0, // 开票

                firstRefundPeriodStartType: 0, // 回款
                firstRefundPeriod: 0,
                firstRefundPeriodDateType: 0,

                balanceInvoicePeriodStartType: 0,
                balanceInvoicePeriod: 0,
                balanceInvoicePeriodDateType: 0,
                balanceRefundPeriodStartType: 0,
                balanceRefundPeriod: 0,
                balanceRefundPeriodDateType: 0,
            };
            this.otherPayments.push(item);
        },
        // 验证全款首付尾款的输入字段
        validateFirstAndBalancePayment(){
            if(this.defaultPayment.overWarrantyPeriod == 0) {
                return shortTips(`保证期不能为空`);
            }

            if(this.defaultPayment.paymentMethodType == 1) { //首尾
                if(this.defaultPayment.firstPaymentProportion + this.defaultPayment.balancePaymentProportion != 100) {
                    return shortTips(`首尾款付款比例加起来必须是100%`);
                } 
            }
            if(this.defaultPayment.paymentMethodType == 0) { //全
                if(this.defaultPayment.firstInvoicePeriod == 0 || this.defaultPayment.firstRefundPeriod == 0){
                    return shortTips(`全款开票时间、回款时间不能为空`);
                }
            }
            if(this.defaultPayment.paymentMethodType == 1) { //首尾
                if(this.defaultPayment.firstInvoicePeriod == 0 || this.defaultPayment.firstRefundPeriod == 0){
                    return shortTips(`首款款开票时间、回款时间不能为空`);
                }
                if(this.defaultPayment.balanceInvoicePeriod == 0 || this.defaultPayment.balanceRefundPeriod == 0){
                    return shortTips(`尾款款开票时间、回款时间不能为空`);
                }
            }
            return true;
        },
        handleTap (type) {
            // validate
            if(type == 'edit' || type == 'add') {
                if(this.defaultPayment.paymentCategory == 1 && this.defaultPayment.jobGrade == '') {
                    return shortTips(`职级不能为空`);
                }

                if(this.defaultPayment.paymentCategory == 2 ) {
                    if(this.defaultPayment.minAnnualSalary == 0 || this.defaultPayment.maxAnnualSalary == 0) {
                        return shortTips(`薪资不能为空`);
                    }
                    if(+this.defaultPayment.maxAnnualSalary <= +this.defaultPayment.minAnnualSalary) {
                        return shortTips(`薪资最大值必须大于最小值`);
                    }
                }
            }

            if(!this.validateFirstAndBalancePayment()){
                return;
            }

            if(type == 'create') {
                if(this.otherPayments.length > 0) {
                    for(let i = 0; i<this.otherPayments.length; i++) {
                        let it = this.otherPayments[i];
                        if(it.paymentCategory == 1 && it.jobGrade == '') {
                            return shortTips(`其它付款方式职级不能为空`);
                        }

                        if(it.paymentCategory == 2 ) {
                            if(it.minAnnualSalary == 0 || it.maxAnnualSalary == 0) {
                                return shortTips(`其它付款方式薪资不能为空`);
                            }
                            if(it.maxAnnualSalary <= it.minAnnualSalary) {
                                return shortTips(`其它付款方式薪资最大值必须大于最小值`);
                            }
                        }
                    }
                }
            }

            console.log(`type--------:`, type);
            console.log(this.defaultPayment);
            // return;
            const _data = {
                defaultPayment: this.defaultPayment,
                otherPayments: this.otherPayments,
            };
            this.$emit('hold-cb', type, _data);

            this.dialogFormVisible = false;
        },
        querySearch(queryString, cb) {
            const arr = this.customerJobLevels.map(it => ({
                ...it,
                value: it.name,
            }));
            var results = queryString ? arr.filter(it => it.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0) : arr;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        handleSelect(item) {
            console.log(item);
        },

        handleOtherItemDelete(index) {
            this.otherPayments.splice(index, 1);
        },

        handleFirstPaymentChange(val) {
            this.defaultPayment.balancePaymentProportion = 100 - this.defaultPayment.firstPaymentProportion;
        },
        handleItemFirstPaymentChange(payItem) {
            payItem.balancePaymentProportion = 100 - payItem.firstPaymentProportion;
        },

        handleBeforeClose() {
            // this.resetForm();
        },
    },
}
</script>

<style lang="scss" scope>
.payment-dialog {
    .conract-header {
        width: 60%;
        display: inline-flex;
        align-items: center;
        .step {
            color: #666;
            font-size: 16px;
        }
        .line {
            height: 1px;
            width: 180px;
            background: #ccc;
            margin-left: 16px;
            margin-right: 16px;
        }
        .active {
            color: $primary;
            font-size: 18px;
            font-weight: bold;
        }
    }
    .el-dialog__body {
        max-height: 680px;
        // max-height: 660px;
        overflow-y: auto;
        .box-card {
            width: 100%;
            .el-card__header {
                padding: 6px 20px;
                .card-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .ht-cell {
                        .width30 {
                            width: 135px;
                            margin-left: 5px;
                        }
                        .del {
                            color: $red;
                        }
                    }
                    .opt {
                        display: inline-flex;
                        justify-content: end;
                        align-items: center;
                    }
                }
            }
            .card-head {
                width: 100%;
                .salary-space{
                    text-align: center;
                    line-height: 32px;
                }
                .salary-ipt{
                    // width: 139px;
                    .append-btn{
                        width: 10px;
                        text-align: center;
                        text-align: center;
                        color: #999;
                    }
                }
                
            }
            .width30 {
                width: 30%;
                margin-right: 5px;
            }
            .el-row {
                margin-bottom: 6px;
            }
        }

        .mid-cell {
            width: 100%;
            padding: 6px 0 0;
            display: flex;
            justify-content: end;
        }
    }

    .dialog-footer {
        .pre-wrap {
            display: inline-flex;
            justify-content: flex-start;
        }
    }
}
</style>